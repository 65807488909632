<template>  
<div class="container-fluid">
  <b-overlay :show="show_overlay_full" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
 <div class="table-responsive">
   <table class="table table-hover table-bordered table-striped table-sm" style="width:100%">
  <thead>
    <tr>
      <th>#</th>
      <th>ID</th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Username</th>
      <th>Email</th>
      <th>Status(Change)</th>
      <th>Login Time</th>
      <th>Delete User</th>
      <th>Change Password</th>
      <th>Profile Image</th>
    </tr>
  </thead>
  <tbody>
   <tr v-for="(user,index) in users" :key="user.ondex" 
      v-show="((currentPage_users-1)*perPage_users<index+1) && (index+1<currentPage_users*perPage_users+1)">
      <th v-text="index+1"></th>
      <td v-text="user.id"></td>
      <td v-text="user.first_name"></td>
      <td v-text="user.last_name"></td>
      <td v-text="user.username"></td>
      <td v-text="user.email"></td> 
      <td>
        <a href="" @click.prevent="change_user_status(user.id,user.first_name,user.last_name,user.status)"
          v-text="user.status">
        </a>
      </td>
      <td v-text="user.new_login"></td>
      <td><a href="" @click.prevent="delete_user(user.id,user.first_name,user.last_name)">Delete</a></td>
      <td><a href="" 
      @click.prevent="show_change_password_modal=true;id=user.id;first_name=user.first_name;last_name=user.last_name">
           Set Password
        </a>
      </td>
      <td> 
            <b-avatar v-if="user.profile_image" :src="'data:image/jpeg;base64,'+user.profile_image"/>
            <b-avatar v-if="!user.profile_image" variant="primary" :text="user.first_name"/>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td style="text-align:left;border-bottom:0;border-left:0;border-right:0;" colspan="2">
         <a href="" @click.prevent="show_new_user_modal=true;id='';first_name='';last_name=''">New User</a>
            
      </td>
      <td style="border-bottom:0;border-left:0;border-right:0;" colspan="7">
        <b-pagination
      v-model="currentPage_users"
      :total-rows="users.length"
      :per-page="perPage_users"
      aria-controls="my-table"
       first-number
       last-number 
       pills            
       align="center"
    ></b-pagination>
      </td>
      <td style="border-bottom:0;border-left:0;border-right:0;" colspan="2">
        
      </td>
    </tr>
  </tfoot>
</table>
</div>

    <!-- Change Password modal  --->
     <b-modal
      v-model="show_change_password_modal"
      title="Change Password"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant="warning"
      footer-text-variant="dark"
    >
      <b-container fluid>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">ID</b-col>
          <b-col v-text="id"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">First Name</b-col>
          <b-col v-text="first_name"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Last Name</b-col>
          <b-col v-text="last_name"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">New Password</b-col>
          <b-col  cols="6">
            <b-form-input type="password" v-model="new_password" size="sm"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Re-new Password</b-col>
          <b-col  cols="6">
            <b-form-input type="password" v-model="re_new_password" size="sm"/>
          </b-col>
        </b-row>
      </b-container>
       <template v-slot:modal-footer>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show_change_password_modal=false"
          >
            Close
          </b-button>
          <b-button :disabled="re_new_password!=new_password || new_password.length<1"
            variant="primary"
            size="sm"
            class="float-right"
            @click="change_password(id)"
          >
            Save
          </b-button>
      </template>
    </b-modal>

    <!-- New User Modal  --->
    <b-modal
      v-model="show_new_user_modal"
      title="Change user status"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant="warning"
      footer-text-variant="dark"
    >
      <b-container fluid>
         <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">ID</b-col>
          <b-col  cols="6">
            <b-form-input type="text" :state="!id.length<1" v-model="id" size="sm"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">First Name</b-col>
          <b-col  cols="6">
            <b-form-input type="text" :state="!first_name.length<1" v-model="first_name" size="sm"/>
          </b-col>
        </b-row>
       <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Last Name</b-col>
          <b-col  cols="6">
            <b-form-input type="text" v-model="last_name" size="sm"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Username</b-col>
          <b-col  cols="6">
            <b-form-input type="text" :state="!username.length<1" v-model="username" size="sm"/>
          </b-col>
        </b-row>
         <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Email</b-col>
          <b-col  cols="6">
            <b-form-input type="email" v-model="email" size="sm"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Status</b-col>
          <b-col>
            <b-form-checkbox v-model="user_status"  switch size="sm">
              {{user_status?'Active':'Deactive'}}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Password</b-col>
          <b-col  cols="6">
            <b-form-input type="password" :state="!new_password.length<1" v-model="new_password" size="sm"/>
          </b-col>
        </b-row>
         <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Re Password</b-col>
          <b-col  cols="6">
            <b-form-input type="password" :state="new_password==re_new_password && !re_new_password.length<1"
                          v-model="re_new_password" size="sm"/>
          </b-col>
        </b-row>
      </b-container>
       <template v-slot:modal-footer>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show_new_user_modal=false"
          >
            Close
          </b-button>
          <b-button 
            variant="primary"
            size="sm"
            class="float-right"
            @click="add_user()"
            :disabled="username=='' || first_name=='' 
                      || new_password!=re_new_password 
                      || new_password.length<1 || id.length<1"
          >
            Add User
          </b-button>
      </template>
    </b-modal>  
</b-overlay>
</div>
</template>
<script>
  export default {
    data() {
        return{
          show_overlay_full:false, //show overlay for full page
          show_change_password_modal:false,
          show_new_user_modal:false,
          currentPage_users:1, //current page number for pagination
          perPage_users:11, //per page in table
          id:'',
          first_name:'',
          username:'',
          last_name:'',
          profile_image_contents:'',
          email:'',
          user_status:false,
          new_password:'',
          re_new_password:'',
          users:[''], //list of users          
       }
      },//data()     
    methods:{

      /***** this function add new user */
      add_user(){
        this.show_change_password_modal=false;
        this.show_overlay_full=true;
        var formData=new FormData();
        var status=this.user_status?'Active':'Deactive';
        formData.append('id',this.id);
        formData.append('first_name',this.first_name);
        formData.append('last_name',this.last_name);
        formData.append('email',this.email);
        formData.append('username',this.username);
        formData.append('status',status);
        formData.append('password',this.new_password);
                 axios.post('/add_user',formData)
                      .then(respose=>{
                        var data=respose.data;
                         if(data=='ok'){
                           this.show_new_user_modal=false;
                          this.show_messagebox('Successfully','Add User','success'); 
                          this.id='';
                          this.first_name='';
                          this.last_name='';
                          this.email='';
                          this.user_status=false;
                          this.username='';
                          this.profile_image_contents='';
                          this.new_password='';
                          this.load_users();
                          return; 
                         }
                         if(data=='error') this.show_messagebox('Unsuccessfully','Add New User','danger');  
                         if(data=='error_id') this.show_messagebox('Unsuccessfully,Duplicate ID','Add New User','danger');                    
                         if(data=='error_username') this.show_messagebox('Unsuccessfully,Duplicate Username','Add New User','danger'); 
                        }).catch(function(error){
                            //console.log(error);
                        }).finally(() => this.show_overlay_full=false);

      },//add_user()
      /**** chanage passord */
      change_password(id){
        this.show_change_password_modal=false;
        this.show_overlay_full=true;
        var formData=new FormData();
        formData.append('id',id);
        formData.append('new_password',this.new_password);
                 axios.post('/change_user_password',formData)
                      .then(respose=>{
                         if(respose.data=='ok'){
                          this.show_messagebox('Successfully','Change User Password','success'); 
                          this.new_password='';
                          this.re_new_password='';
                          return; 
                         } 
                         this.show_messagebox('Unsuccessfully','Change User Password','danger');                     
                        }).catch(function(error){
                            //console.log(error);
                        }).finally(() => this.show_overlay_full=false);

      },//change_password(id)
        
      //load list of user
      load_users(){
           axios.get('/get_all_users')
               .then(respose=>{
                    this.users=respose.data;
                     //console.log(this.users);
                     }).catch(function(error){
                       //console.log(error);        
                 }); 

      },

        /**** this method change user status */
        change_user_status(id,first_name,last_name,status){
          var new_status=status=='Active'?'Deactive':'Active';
           this.$bvModal.msgBoxConfirm('Are you sure Change Status User: '+first_name+' '
                                       +last_name+' to '+new_status+'?', {
            title: 'Confirm Change Status',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
              if(value){
                 //clear errors
                 this.show_overlay_full=true;
                 var formData=new FormData();
                 formData.append('id',id);
                 formData.append('new_status',new_status);
                 axios.post('/change_user_status',formData)
                      .then(respose=>{
                        if(respose.data=='default_user'){
                          this.show_messagebox('Unsuccessfully,No Active another User status','Change User Status','danger'); 
                          return;
                        }
                         if(respose.data=='ok'){
                          this.show_messagebox('Successfully','Change User Statusr','success'); 
                          this.load_users();
                          return; 
                         } 
                         this.show_messagebox('Unsuccessfully','Change User Status','danger');                     
                        }).catch(function(error){
                            //console.log(error);
                        }).finally(() => this.show_overlay_full=false); 
                       } //if(value){
                   })  // }).then(value => { 

        },//change_user_status(id,first_name,last_name)

        /**** this method delete user */
        delete_user(id,first_name,last_name){ 
           this.$bvModal.msgBoxConfirm('Are you sure delete user :'+first_name+' '
                                       +last_name+' ?', {
            title: 'Confirm Delete',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if(value){
              this.show_overlay_full=true;
              var formData=new FormData();
                 formData.append('id',id);
                 axios.post('/delete_user',formData)
                      .then(respose=>{
                        if(respose.data=='default_user'){
                          this.show_messagebox('Unsuccessfully,No Active another User status','Delete User','danger'); 
                          return;
                        }
                         if(respose.data=='ok'){
                          this.show_messagebox('Successfully','Delete User','success'); 
                          this.load_users();
                          return; 
                         } 
                         this.show_messagebox('Unsuccessfully','Delete User','danger');                     
                        }).catch(function(error){
                            //console.log(error);
                        }).finally(() => this.show_overlay_full=false); 
                       } //if(value){
          })    
        },//delete_user(id,first_name,last_name)
        
        /**** this method show message box */
        show_messagebox(text,title,okVariant){
         this.$bvModal.msgBoxOk(text, {
                         title:title,
                         size: 'sm',
                         buttonSize: 'sm',
                         okVariant: okVariant,
                         headerClass: 'p-2 border-bottom-0',
                         footerClass: 'p-2 border-top-0',
                         centered: true
                         });
      },// show_messagebox(text,title,okVariant) 
       
     },//methods
    mounted(){
      this.load_users();
    },//mounted

  }
</script>

