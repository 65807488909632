<template>
<div class="container-fluid">
<b-overlay :show="show_overlay_full" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
      <div class="row">
      <div class="col-12 bg-light">Calendar Images Setting</div>
      </div>
      <div class="row">
      <div class="col-sm-2 bg-light">
        <label for="images_list">Images List</label>
        <select style="height:400px" size="2" :disabled="changed_image_properties"
              :class="changed_image_properties ?'form-control disabled':'form-control'" 
              id="images_list"
              v-model="selected_image_id" @change="change_image_list"
        >
           <option  v-for="img in all_images" :key="img.index" :value="img.image_id">
            {{img.image_name}}
        </option>
        </select>
        </div>
      <div class="col-sm-4">
        <figure class="figure figure-fluid">
          <figcaption class="figure-caption">Calendar Image Preview.</figcaption>
          <b-overlay :show="show_overlay_image" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
             <img v-show="image_contents!=null" :src="'data:'+image_type+';base64,'+image_contents" class="figure-img img-fluid z-depth-1"
                alt="..." style="width:400px;height:400px"> 
          </b-overlay>    
        </figure>
      
      </div>
      <div class="col-sm-6 bg-light">
        <b-row class="my-1">
          <b-col sm="12"> Image Propertires</b-col>
        </b-row>  
        <b-row class="my-1">
          <b-col sm="1"></b-col>
          <b-col sm="1">
            <label for="image_name">Name:</label>
          </b-col>
          <b-col sm="4">
            <b-form-input id="image_name" v-model="image_name" @change.native="get_form_input" size="sm" 
                          placeholder="Image name" :state="image_name!=''" /> </b-col>
          <b-col sm="1" >Default:</b-col>
          <b-col sm="1">
              <b-form-checkbox size="sm" v-model="default_image" value="true" unchecked-value="false" 
                               @change.native="get_form_input"/>
          </b-col>
        </b-row>
         <b-row class="my-1">
          <b-col sm="1"></b-col>
          <b-col sm="1">
            <label for="image_description">Desc:</label>
          </b-col>
          <b-col sm="6">
            <b-form-input id="image_description" v-model="image_description"  @change.native="get_form_input" 
                         size="sm" placeholder="Image Description"/>
          </b-col>
        </b-row>
         <b-row class="my-1">
           <b-col sm="1"></b-col>
           <b-col sm="1">
             <label for="image_width">Width:</label>
           </b-col>
           <b-col sm="2">
             <b-form-input id="image_width" type="number" min="1" max="1000" @change.native="get_form_input"
                            v-model="image_width" size="sm" placeholder="Width"/>
          </b-col>
           <b-col sm="1">
             <label for="image_height">Height:</label>
           </b-col>
           <b-col sm="2">
             <b-form-input id="image_height" type="number" min="1" max="1000" @change.native="get_form_input"
                          v-model="image_height" size="sm" placeholder="Height"/>
          </b-col>
         </b-row>
         <b-row class="my-1">
          <b-col sm="12">Calendar Options</b-col>
        </b-row>
         <b-row class="my-1">
           <b-col sm="1"></b-col>
          <b-col sm="4">Date For Set Options</b-col>
          <b-col sm="3">
            <b-form-input type="date" v-model="date_for_set_options" size="sm" @change.native="get_form_input"/>
          </b-col>
         </b-row>
         <b-row class="my-1">
           <b-col sm="1"></b-col>
          <b-col sm="3">Calendar Type:</b-col>
          <b-col sm="6">
           <b-form-radio-group v-model="selected_calendar_type" @change.native="set_form_input">
             <b-form-radio value="Miladi">Miladi</b-form-radio>
             <b-form-radio value="Shamsi">Shamsi</b-form-radio>
             <b-form-radio value="Gamari">Gamari</b-form-radio>
           </b-form-radio-group>
          </b-col>
         </b-row>
         <b-row class="my-1">
           <b-col sm="1"></b-col>
         <b-col sm="3">Select Element:</b-col>
          <b-col sm="7">
           <b-form-radio-group v-model="selected_element" @change.native="set_form_input">
             <b-form-radio value="Year">Year</b-form-radio>
             <b-form-radio value="Mounth">Mounth</b-form-radio>
             <b-form-radio value="Weekday">Weekday</b-form-radio>
             <b-form-radio value="Day">Day</b-form-radio>
           </b-form-radio-group>
          </b-col>
         </b-row>
         <b-row class="my-1">
           <b-col sm="2"></b-col>
          <b-col sm="10"><strong v-text="selected_element+' Options'"></strong></b-col>
        </b-row>
         <b-row class="my-1">
           <b-col sm="3"></b-col>
          <b-col sm="1" >Visible:</b-col>
          <b-col sm="1">
              <b-form-checkbox size="sm" v-model="element_visible" value="true" unchecked-value="false" 
                               @change.native="get_form_input"/>
          </b-col>
        </b-row>
         <b-row class="my-1">
           <b-col sm="3"></b-col>
          <b-col sm="1">Color:</b-col>
          <b-col sm="2">
            <b-form-input type="color" v-model="element_color" size="sm" @change.native="get_form_input"/>
          </b-col>
          <b-col sm="1">Font:</b-col>
          <b-col sm="2">
             <b-form-select v-model="element_font" :options="all_fonts" size="sm" @change="get_form_input"/>
          </b-col>
        </b-row>
         <b-row class="my-1">
           <b-col sm="3"></b-col>
          <b-col sm="1">Angle:</b-col>
          <b-col sm="2">
            <b-form-input type="number"  v-model="element_angle" min="0" max="360" size="sm" @change.native="get_form_input"/>
          </b-col>
          <b-col sm="1">Size:</b-col>
          <b-col sm="2">
             <b-form-input type="number" v-model="element_size"  min="0" max="100" size="sm" @change="get_form_input"/>
          </b-col>
        </b-row>
         <b-row class="my-1">
           <b-col sm="3"></b-col>
          <b-col sm="1">X:</b-col>
          <b-col sm="2">
            <b-form-input type="number" v-model="element_x" min="0" :max="image_width" size="sm" @change.native="get_form_input"/>
          </b-col>
          <b-col sm="1">Y:</b-col>
          <b-col sm="2">
            <b-form-input type="number" v-model="element_y" min="0" :max="image_height" size="sm" @change.native="get_form_input"/>
          </b-col>
        </b-row>
        
         
      </div>
      </div>
      <div class="row">
      <div class="col-12 bg-light">
          <b-button variant="primary" :class="!changed_image_properties? 'disabled':''" size="sm" @click="update_image_info">Save</b-button>
          <b-button variant="primary" size="sm" v-show="changed_image_properties" @click="cancel_update">Cancel</b-button>
          <b-button variant="primary" size="sm" v-show="!changed_image_properties" @click="delete_selected_image">Delete</b-button>
          <b-form-file ref="new_image" accept=".jpg, .jpeg, .png, .gif" style="display: none"
                        @change="upload_new_image"/>
          <b-button @click="$refs.new_image.$el.childNodes[0].click()" variant="primary" size="sm">New</b-button> 
      </div>
      </div>
       <div class="row">
      <div class="col-12" style="direction:rtl; text-align:right;">
          <span v-if="shamsi_occasion!=''">{{shamsi_occasion}}<br></span>
					<span v-if="gamari_occasion!=''">{{gamari_occasion}}<br></span>
					<span v-if="miladi_occasion!=''">{{miladi_occasion}}</span>
      </div>
      </div>
      <br>
</b-overlay>
</div>
</template>
<script>
  export default {
    //name: 'ListGroupPage',
    data() {
       return {
         show_overlay_full:false,
         show_overlay_image:false,
         image_name:'',
         all_images:[], //save all image list
         selected_image_id:'-2',
         image_contents:null,//
         original_image_contents:null,//contents of not changed image contents
         image_type:'',
         image_width:200,
         image_height:200,
         image_description:'', 
         default_image:false, //if selected image is default
         date_for_set_options: new Date().toISOString().slice(0,10), //date for config calendar
         selected_calendar_type:'Miladi', //selected calendar
         selected_element:'Year', //selected element
         element_color:'#040503', //element color
         element_font:'', //selected font
         all_fonts:[], //save all font file names
         element_visible:true, //check for visible element
         element_angle:0, //angle
         element_x:0, //position x
         element_y:0,  //position y
         element_size:5,//size of element
         all_avalible_size:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
                            21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40],
         date_miladi:null, 
         date_shamsi:null,
         date_gamari:null,
         changed_image_properties:false,//check image propeties changeed
         show_browse_image:false, //save status show or hide input element
         browsed_image:[], //save file browsed only use for clear input tag   
         shamsi_occasion:'',
				 miladi_occasion:'',
				gamari_occasion:'',         
       }
    },
     methods:{
       
       /*****this method cancel update **/
       cancel_update(){
         this.changed_image_properties=false;
         this.image_contents=null;
          this.original_image_contents=null;
         this.browsed_image=[]; //clear input tag 
         this.get_all_image_list(); //refresh image list
       },//cancel_update()

       /****** this method delete seletcted image */
       delete_selected_image(){
          if(this.default_image=="true"){   //if selected image is default
             this.show_messagebox('Image for delete can\'t be dafult image \n for delete this image first change default image properties',
                                  'Warning delete','danger');           
               return;// cancel delete               
          }// if(this.default_image=="true")
           this.$bvModal.msgBoxConfirm('Are you sure delete image: '+this.image_name+' ?', {
            title: 'Confirm Delete',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
              this.changed_image_properties=false;
              if(value){
                 //delete image
                 this.show_overlay_full=true;
                 axios.get('/deleteImage/'+this.selected_image_id)
                      .then(respose=>{
                             if(respose.data=='error'){
                                this.show_messagebox('Cant not delete image','Error','danger'); 
                                return;//
                              } 
                           if(respose.data=='ok'){//delete successfuly successfully
                           this.selected_image_id='-2';
                             this.get_all_image_list(); //refresh image list
                             this.show_messagebox('successfully image delete','Information','success');
                         } // if(respose.data==1)                
                         else{ //check for other reason
                             this.show_messagebox('Unsuccessfully image delete ','Information','danger'); 
                            }//else
                          }).catch(function(error){
                              //console.log(error);
                             }).finally(() => this.show_overlay_full=false);
                       } //if(value){     
                   })  // }).then(value => {
                    .catch(err => {
                        //console.log(e)
                  }) 
       },//delete_selected_image()

       /****** this method run when click on image list */
       /****** this method show image properties on form */
       change_image_list(){    
         this.show_browse_image=false;
         this.show_overlay_full=true;
         axios.get('/getAllInfoCalendarImage/'+this.selected_image_id)
                .then(respose=>{   
                      if(respose.data=='error'){
                   this.show_messagebox('Cant not get image','Error','danger'); 
                   return;//
                 }             
                    var imgInfo=respose.data;
                    this.image_name=imgInfo.image_name;
                    this.image_width=imgInfo.width;
                    this.image_height=imgInfo.height; 
                    this.default_image=imgInfo.default_image;
                    this.image_description=imgInfo.description;
                    this.date_miladi=imgInfo.date_miladi; 
                    this.date_shamsi=imgInfo.date_shamsi; 
                    this.date_gamari=imgInfo.date_gamari; 
                    this.image_type=imgInfo.image_type;
                    this.image_contents=imgInfo.image_contents; 
                    this.original_image_contents=imgInfo.image_contents;
                    this.set_form_input();
                    }).catch(function(error){
                        //console.log(error);
                 }).finally(() => this.show_overlay_full=false);

       },//change_image_list

       //when change form input get values
       get_form_input(){
         this.changed_image_properties=true; //set image properties change
         this.show_browse_image=false; //hide browse image input
         //check if height less or equal than one set one
         if(this.image_height<=1) this.image_height=1;

         //check if width less or equal than one set one
         if(this.image_width<=1) this.image_width=1;

         var calendar_type=(this.selected_calendar_type).toLowerCase();;
         var element=(this.selected_element).toLowerCase();
         var miladi=this.date_miladi;
         var shamsi=this.date_shamsi;
         var gamari=this.date_gamari;

         var value;
        
         value=this.element_visible;
         eval(calendar_type+'.'+element+'.visible=value');

         value=this.element_color;
         eval(calendar_type+'.'+element+'.color=value');
         value=this.element_font;
         eval(calendar_type+'.'+element+'.font=value');
         value=this.element_angle;
         eval(calendar_type+'.'+element+'.angle=value');
         value=this.element_size;
         eval(calendar_type+'.'+element+'.size=value');
         value=this.element_x;
         eval(calendar_type+'.'+element+'.x=value');
         value=this.element_y;
         eval(calendar_type+'.'+element+'.y=value');

         this.date_miladi=miladi;
         this.date_shamsi=shamsi;
         this.date_gamari=gamari;
         this.set_properties_calendar_image();
      },//get_form_input
      
      /****** this method get properties calendar and send to create calendar image */
      set_properties_calendar_image(){
        var calendar={
          
          'input_type':'image_properties',
          'image_id':'26',
          'image_contents':this.original_image_contents,
          'width':this.image_width,
          'height':this.image_height,
          'date_miladi_prop':this.date_miladi,
          'date_gamari_prop':this.date_gamari,
          'date_shamsi_prop':this.date_shamsi,
          'date_for_config':this.date_for_set_options,
          };
         this.show_overlay_image=true; 
        axios.post('/getCalendarImage',calendar)
             .then(respose=>{    
               var data=respose.data;
               //console.log(respose.data); 
                 if(data=='error'){
                   this.show_messagebox('Cant not get image','Error','danger'); 
                   return;//
                 }
                 this.image_contents=data.image_contents;
			           this.shamsi_occasion=data.shamsi_occasion;
                 this.miladi_occasion=data.miladi_occasion;
				         this.gamari_occasion=data.gamari_occasion;              
                 //this.image_contents=respose.data; 
                 }).catch(function(error){
                          //console.log(error);
                 }).finally(() => this.show_overlay_image=false);
      },//set_properties_calendar_image()

       //set form input from date_* variable
       set_form_input(){
         var calendar_type=(this.selected_calendar_type).toLowerCase();;
         var element=(this.selected_element).toLowerCase();
         var miladi=this.date_miladi;
         var shamsi=this.date_shamsi;
         var gamari=this.date_gamari;
         
         var el;
         eval('el='+calendar_type+'.'+element+'.size');
         this.element_size=el;
         eval('el='+calendar_type+'.'+element+'.color');
         this.element_color=el;
         eval('el='+calendar_type+'.'+element+'.font');
         this.element_font=el;
         eval('el='+calendar_type+'.'+element+'.visible');
         this.element_visible=el;
         eval('el='+calendar_type+'.'+element+'.angle');
         this.element_angle=el;
         eval('el='+calendar_type+'.'+element+'.x');
         this.element_x=el;
         eval('el='+calendar_type+'.'+element+'.y');
         this.element_y=el;
         this.set_properties_calendar_image();
      },//set_form_input

       /*****this method update and add new image */ 
       update_image_info(){
          if(this.image_name==''){ //check for valid name for image
            //show message for select valid name for image and cancel update
            this.show_messagebox('Please enter image name','Information','danger');  
           return;      
          }// if(this.image_name=='')
         var imgUpdate={
           'image_id':this.selected_image_id,
           'image_name':this.image_name,
           'image_type':this.image_type,
           'image_contents':this.original_image_contents,
           'width':this.image_width,
           'height':this.image_height,
           'default_image':this.default_image,
           'description':this.image_description,
           'date_miladi':this.date_miladi,
           'date_shamsi':this.date_shamsi,
           'date_gamari':this.date_gamari,
           };
           this.show_overlay_full=true;
           axios.post('/updateImage',imgUpdate)
               .then(respose=>{
                 if(respose.data=='error'){
                   this.show_messagebox('Cant not save image change','Error','danger'); 
                   return;//error Save
                 }   
                 if(respose.data=='error name'){//check for duplicate name
                   this.image_name='';
                   this.show_messagebox('Image\'s name is duplicate','Information','danger'); 
                   return;
                } // if(respose.data=='error name')
                 if(respose.data=='error default image'){//check for duplicate name
                   this.show_messagebox('unckeched default image, But not selected new default image',
                                        'Warning Update','danger'); 
                   return;
                } // if(respose.data=='error name')
               if(respose.data=='ok'){//update successfully
                    this.changed_image_properties=false; //
                    this.get_all_image_list(); //refresh image list
                    this.show_messagebox('successfully image update','Information','success'); 
                } // if(respose.data==1)                
               else{ //check for other reason
                 this.show_messagebox('Unsuccessfully image update ','Information','danger'); 
                 }//else
                }).catch(function(error){
                  //console.log(error);
             }).finally(() => this.show_overlay_full=false); 

       },//update_image_info

       /*******this method upload new image */
       upload_new_image(e){
         this.selected_image_id=-1;//set for new image add
         this.image_name='';
         this.default_image='false';
         this.image_description='';
         var files = e.target.files || e.dataTransfer.files;
         if (!files.length) return;
         //create image
         var file=files[0];
         var reader = new FileReader();
         reader.onload = (e) => {
                var image_base64= e.target.result;
                this.image_type=file.type;
                var strBase64='data:'.concat(this.image_type).concat(';base64,'); 
                this.image_contents=image_base64.slice(strBase64.length);
                this.original_image_contents=this.image_contents;
                this.show_browse_image=false;
                this.changed_image_properties=true;
                 
                };
        reader.readAsDataURL(file);
        
       }, //upload_new_image   
       
       /****** this method get image_id and return image_contents */  
       setImageContents(image_id){
        this.show_overlay_image=true;
        axios.get('/getCalendarImageContents/'+image_id)
                .then(respose=>{  
                  var data=respose.data;   
                  if(data=='error'){
                   this.show_messagebox('Cant not get image','Error','danger'); 
                   return;//
                 }          
                 this.image_contents=data; 
                 this.original_image_contents=data;
                 }).catch(function(error){
                        //console.log(error);
                 }).finally(() => this.show_overlay_image=false);

       }, //setImageContents 

       //this method get all images name and id
       get_all_image_list(){
         this.show_overlay_full=true;
         axios.get('/getAllImagesNames_ids')
                .then(respose=>{  
                      if(respose.data=='error'){
                   this.show_messagebox('Cant not get images name','Error','danger'); 
                   return;//
                 }              
                    this.all_images=respose.data;
                    
                    //check not in update and new status
                    if(this.selected_image_id!='-2' && this.selected_image_id!='-1'){
                      this.change_image_list();
                      this.setImageContents(this.selected_image_id);   
                      //this.image_name
                      return;
                    }
                    
                    if(this.all_images[0]!='undefined'){ //check not empty image list
                      this.selected_image_id=this.all_images[0].image_id;
                      this.change_image_list();
                      this.setImageContents(this.selected_image_id);                 
                      }//if(this.all_images[0]!='undefined')                    
                    }).catch(function(error){
                        //console.log(error);
                 }).finally(() => this.show_overlay_full=false);   
       },//get_all_image_list() 

      /******* show messagebox ************/
      show_messagebox(text,title,okVariant){
         this.$bvModal.msgBoxOk(text, {
                         title:title,
                         size: 'sm',
                         buttonSize: 'sm',
                         okVariant: okVariant,
                         headerClass: 'p-2 border-bottom-0',
                         footerClass: 'p-2 border-top-0',
                         centered: true
                         });

      },// show_messagebox(text,title,okVariant)  

     },//methods
      mounted(){
         var el_attes={'color':'#0f020f','size':'5','angle':'0','font':'font name','x':'0','y':'0','visible':'false'};
         this.date_miladi={'year':el_attes,'mounth':el_attes,'weekday':el_attes,'day':el_attes};
         this.date_shamsi={'year':el_attes,'mounth':el_attes,'weekday':el_attes,'day':el_attes};
         this.date_gamari={'year':el_attes,'mounth':el_attes,'weekday':el_attes,'day':el_attes};

        this.get_all_image_list();
        var formData=new FormData();
        formData.append('action','get');
        axios.post('/font_actions',formData)
                .then(respose=>{    
                    if(respose.data=='error'){
                   this.show_messagebox('Cant not get font list','Error','danger'); 
                   return;//
                 }              
                    this.all_fonts=respose.data; 
                    this.element_font=this.all_fonts[0];
                    }).catch(function(error){
                     //   console.log(error);
                 }); 

       }, //mounted
  } //export default
</script>