<template>
<div class="container-fluid">
   <b-overlay :show="show_overlay" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">

     <!---------------------------- send config Calendar      ------------------>
    <!------------------------------------------------------------------------>
       <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-config href="#">Change Calendar Configuration</a>
      </b-card-header>
      <b-collapse id="accordion-config" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div class="container">
              <div class="row">
                <div class="col-sm-4">
                  <label for="change_day_gamari">Change Day Gamari: </label>
                </div>
                <div class="col-sm-2">  
                  <b-form-input id="change_day_gamari" type="number" min="-2" max="2" v-model="change_day_gamari"  
                              @change="change_config=true" size="sm"/>  
                </div>
                <div class="col-sm-6">  </div>  
              </div>
              <br/>
               <div class="row">
                <div class="col-sm-4">
                  <label for="allow_send_calendar_to_all_list">Allow Send Calendar To All List: </label>
                </div>
                <div class="col-sm-6">  
                  <b-form-checkbox id="allow_send_calendar_to_all_list" v-model="allow_send_calendar_to_all_list"  
                                    @change="change_config=true" switch/>
                </div>
                <div class="col-sm-2">  </div>  
              </div>
              <br/>
               <div class="row">
                <div class="col-sm-4">
                  <label for="change_gamari_1th_30th_day">Change Gamari 1th To 30th Day: </label>
                </div>
                <div class="col-sm-2">  
                  <b-form-checkbox id="change_gamari_1th_30th_day" v-model="change_gamari_1th_30th_day" 
                                    @change="change_config=true" switch/>  
                </div>
                <div class="col-sm-6">  </div>  
              </div>
              <br/>
              <div class="row">
                <div class="col-sm-4">
                  <label for="show_occasions">Show Occasions:</label>
                </div>
                <div class="col-sm-2">  
                  <b-form-checkbox id="show_occasions" v-model="show_occasions" 
                                    @change="change_config=true" switch/>  
                </div>
                <div class="col-sm-6">  </div>  
              </div>
              <br/>
               <div class="row">
                <div class="col-sm-4">
                  <label for="message_admins_for_end_gamari_mounth">Message Admins For End Of Gamari Mounth: </label>
                </div>
                <div class="col-sm-2">  
                  <b-form-checkbox id="message_admins_for_end_gamari_mounth" v-model="message_admins_for_end_gamari_mounth"  switch
                  @change="change_config=true"/>  
                </div>
                <div class="col-sm-6">  </div>  
              </div>
              <div class="row">
                <div class="col">
                <b-button @click="config_actions('save')" variant="primary" size="sm" :disabled="!change_config">Save</b-button>
                <b-button @click="config_actions('get')" variant="primary" size="sm" :disabled="!change_config">Cancel</b-button>
                </div>
              </div>
            </div>
             
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
    <!---------------------------- send message config      ------------------>
    <!------------------------------------------------------------------------>

     <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-send-message href="#">Send Message Configuration</a>
      </b-card-header>
      <b-collapse id="accordion-send-message" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <b-overlay :show="show_overlay_send_message" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
            <div class="container">
              <div class="row">
                <div class="col-sm-1">
                  <label for="send_message_to_selected">Send To:</label>
                </div>
                <div class="col-sm-3">  
                   <b-form-radio-group id="send_message_to_selected" v-model="send_message_to_selected">
                     <b-form-radio value="all">All Members</b-form-radio>
                     <b-form-radio value="one">One Members</b-form-radio>
                   </b-form-radio-group> 
                </div>
                <div class="col-sm-2">
                  <div v-show="send_message_to_selected=='one'">
                    <b-form-input id="send_message_id" v-model="send_message_id"
                                size="sm" placeholder="Member ID" :state="send_message_id!=''"/>
                  </div>  
                </div> 
                <div class="col-sm-6">  </div>   
              </div>
              <div  class="row">
                <div class="col-sm-1"> <label for="message_text">Message:</label></div>
                <div class="col-sm-6">
                    <b-form-textarea
                      :state="message_text!=''"
                      id="message_text"
                      size="sm"
                      rows="4"
                      v-model="message_text"
                      placeholder="Enter Message"
                    >
                   </b-form-textarea>

                </div>
                <div class="col-sm-5"></div>
              </div>               
              <div class="row">
                <div class="col">
                <b-button @click="send_message" variant="primary" size="sm" 
                         :disabled="(send_message_to_selected=='one' && send_message_id=='') || message_text==''">Send</b-button>
                <b-button @click="message_text=''" variant="primary" size="sm" 
                         :disabled="message_text==''">Clear</b-button>
                </div>         
              </div>
            </div> 
             </b-overlay>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!---------------------------- send Calendar Image config      ------------------>
    <!------------------------------------------------------------------------>

     <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-send-calendar-image href="#">Send Calendar Image Now Configuration</a>
      </b-card-header>
      <b-collapse id="accordion-send-calendar-image" accordion="my-accordion" role="tabpanel">
        <b-overlay :show="show_overlay_send_calendar_image" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
        <b-card-body>
          <b-card-text>
            <div class="container">
              <div class="row">
                <div class="col-sm-2">
                  <label for="send_calendar_image_to_selected">Now Send To:</label>
                </div>
                <div class="col-sm-3">  
                   <b-form-radio-group id="send_calendar_image_to_selected" v-model="send_calendar_image_to_selected">
                     <b-form-radio value="all">All Members</b-form-radio>
                     <b-form-radio value="one">One Members</b-form-radio>
                   </b-form-radio-group> 
                </div>
                <div class="col-sm-2">
                  <div v-show="send_calendar_image_to_selected=='one'">  
                    <b-form-input id="send_calendar_image_id" v-model="send_calendar_image_id"
                                size="sm" placeholder="Member ID" :state="send_calendar_image_id!=''"/>
                  </div>  
                </div> 
                <div class="col-sm-5">  </div>   
              </div>              
              <div class="row">
                <b-button @click="send_calendar_image" variant="primary" size="sm" 
                         :disabled="(send_calendar_image_to_selected=='one' && send_calendar_image_id=='')">Send</b-button>
              </div>
            </div> 
          </b-card-text>
        </b-card-body>
        </b-overlay>
      </b-collapse>
    </b-card>

    <!---------------------------- BackUp config      ------------------>
    <!------------------------------------------------------------------------>

    <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-backup href="#">Back up and Restore Database Configuration</a>
      </b-card-header>
      <b-collapse id="accordion-backup" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div class="container">
              <div class="row">
                <div class="col-sm-2">
                  <label for="last_backup_date">Last Backup Date: </label>
                </div>
                <div class="col-sm-2">  
                  <strong><span id="last_backup_date" v-text="last_backup_date"></span></strong>
                </div>
                <div class="col-sm-8">                  
                </div>  
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <label for="last_backup_date">Restore Backup From: </label>
                </div>
                <div class="col-sm-3">  
                   <b-form-radio-group v-model="restore_from_location">
                     <b-form-radio value="last_backup">Last Backup</b-form-radio>
                     <b-form-radio value="upload_sql_file">Upload Sql File</b-form-radio>
                   </b-form-radio-group> 
                </div>
                <div class="col-sm-7">
                 
                </div>  
              </div>
              <div class="row">
                <div class="col-sm-2">
                </div>
                <div class="col-sm-9">
                  <b-form-file v-model="sql_backup_file" ref="backup_file" accept=".sql, .SQL"   
                            style="display:none;"/>
                  <b-button  v-show="restore_from_location=='upload_sql_file'" 
                      @click="$refs.backup_file.$el.childNodes[0].click()" variant="info" size="sm">Browse</b-button>
                    
                  <span v-show="restore_from_location=='upload_sql_file'">
                    <label> Selected:</label>
                    <strong><span id="selected_sql_file_name" v-text="sql_backup_file ? sql_backup_file.name : ''"></span></strong>
                  </span>          
                </div>
                <div class="col-sm-1">
                </div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <label>Backup Action:</label>
                </div>
                <div class="col-sm-8">
                  <b-button @click=" backup_database" variant="primary" size="sm">Backup</b-button>
                  <b-button @click="download_last_backup" variant="primary" :disabled="last_backup_date=='never'"
                             size="sm">Download</b-button>
                  <b-button @click="restore_database" variant="primary" size="sm" 
                      :disabled="(restore_from_location=='upload_sql_file' && !sql_backup_file) ||
                      (restore_from_location=='last_backup' && last_backup_date=='never')" >Restore</b-button>
                </div>
                <div class="col-sm-2">
                </div>
              </div>
            </div>             
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
    
    <!---------------------------- fonts config      ------------------------>
    <!------------------------------------------------------------------------>    
     <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-fonts href="#">Fonts Configuration</a>
      </b-card-header>
      <b-collapse id="accordion-fonts" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div class="container">
              <div class="row">
                <div class="col-sm-3">
                  <label for="fonts_list" class="align-center">Fonts List</label>
                  <b-form-select id="fonts_list" v-model="selected_font_name" 
                                 :options="fonts_list" :select-size="10"
                  ></b-form-select>
                </div>              
                <div class="col-sm-5" 
                      v-show="font_config_status=='rename' || font_config_status=='add'">
                   <br>
                   <br>
                   <br>   
                  <label for="new_font_name">Font Name:</label>
                  <b-form-input id="new_font_name" v-model="new_font_name" min="1"
                                size="sm" placeholder="Enter Font Name" :state="font_name_valid_status"
                                @change="font_actions('check_font_name_vaild')"/>
                  <b-button v-show="font_config_status=='rename'" @click="confirm_rename_selected_font" variant="primary" size="sm" 
                         :disabled="!font_name_valid_status">Rename</b-button>
                  <b-button v-show="font_config_status=='add'" @click="font_actions('upload')" variant="primary" size="sm" 
                         :disabled="!font_name_valid_status">Upload</b-button>       
                  <b-button @click="upload_font_file=null;font_name_valid_status=null;font_config_status=''" variant="primary" size="sm" 
                                                   >Cancel</b-button>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-sm-3"> 
                    <b-button @click="confirm_delete_selected_font" variant="primary" size="sm"
                         :disabled="selected_font_name=='' || font_config_status!=''">Delete</b-button>

                    <b-form-file ref="font_file" v-model="upload_font_file" accept=".ttf, .TTF" id="font_file" style="display: none" 
                                 @change.native="font_actions('add')"/>
                    <b-button @click="$refs.font_file.$el.childNodes[0].click()" variant="primary" size="sm"
                         :disabled="font_config_status=='rename'">Add</b-button>
                  
                    <b-button @click="font_config_status='rename';new_font_name=selected_font_name" variant="primary" size="sm"
                         :disabled="selected_font_name=='' || font_config_status=='add'">Rename</b-button>                  
                </div>   
              </div> 
            </div> 
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!---------------------------- Update Member Informations config      ------------------>
    <!-------------------------------------------------------------------------------------->

     <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-update href="#">Update Members Information Configuration</a>
      </b-card-header>
      <b-collapse id="accordion-update" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div class="container">
              <div class="row">
                <div class="col-sm-4">
                  <label>Update Members Informations Status: </label>
                </div>
                <div class="col-sm-4">
                  <strong><label v-text="update_status"></label> </strong> 
                </div>
                <div class="col-sm-4">
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3"></div>               
                <div class="col-sm-4">      
                  <b-overlay
                    :show="show_overlay_update"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-button @click="update_member_info" variant="info"  size="sm">
                    Update Members Information</b-button> 
                  </b-overlay>  
                </div>  
                <div class="col-sm-5">  </div> 
              </div>
            </div> 
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

     <!---------------------------- occasione config      ------------------>
    <!------------------------------------------------------------------------>

     <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-occasion href="#">Occasion Configuration</a>
      </b-card-header>
      <b-collapse id="accordion-occasion" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <b-overlay :show="show_overlay_occasion" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
            <div class="container">
              <div class="row">
                <div class="col-sm-2">
                  <label for="occasion_type">Occasion Type:</label>
                </div>
                <div class="col-sm-5">  
                   <b-form-radio-group @change.native="occasion_actions('get_all')" id="occasion_type" v-model="occasion_type">
                     <b-form-radio value="shamsi">Shamsi</b-form-radio>
                     <b-form-radio value="gamari">Gamari</b-form-radio>
                     <b-form-radio value="miladi">Miladi</b-form-radio>
                   </b-form-radio-group> 
                </div>
                 <div class="col-sm-5">  </div>   
              </div>

              <b-row>
                <b-col  sm="2">
                  <label for="occasion_day">Occasion Day:</label>
                </b-col>
                <b-col sm="2" md="1">  
                  <b-form-select @change="occasion_actions('get_one')" size ="sm" v-model="occasion_day" :options="occasion_days"></b-form-select>
                </b-col>
                <b-col sm="2" md="6"></b-col>
            </b-row>

              <div class="row">
                <div class="col-sm-2">
                  <label for="occasion_mounth">Occasion Mounth:</label>
                </div>
                <div class="col-sm-2">  
                   <b-form-select @change="occasion_actions('get_one')"  size ="sm" v-model="occasion_mounth" :options="occasion_mounths"></b-form-select>
                </div>
                <div class="col-sm-6"></div>
              </div>
              <div class="row">
                <div class="col-sm-2">
                  <label for="occasion_Title">Occasion Title:</label>
                </div>
                <div class="col-sm-7">  
                  <b-form-textarea style="direction: rtl;"        
                      id="occasion_Title"
                      size="sm"
                      rows="2"
                      v-model="occasion_title"
                    >
                   </b-form-textarea>
              </div>
               <div class="col-sm-4">  </div>   
            </div>
            <br>
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-4">
                    <b-button @click="occasion_actions('save')" variant="primary" size="sm"
                          :disabled="occasion_day=='' || occasion_mounth==''">Save</b-button>
                    <b-button @click="occasion_title=''" variant="primary" size="sm"
                         :disabled="occasion_title==''">Clear</b-button>
              </div> 
              <div class="col-sm-6">  </div>   
            </div>
            <br>
            <div class="row">
              <b-table  striped hover small  style="direction: rtl;text-align:right;"
                        responsive
                        :items="occasions"                      
                        :per-page="perPage_occasions"
                        :current-page="currentPage_occasions"
                        id="occasionss_table">
             </b-table>
             <b-pagination  
                 v-model="currentPage_occasions"
                :total-rows="occasions.length"
                :per-page="perPage_occasions"
                aria-controls="occasions_table"
                first-number
                last-number 
                pills            
                align="center"
              ></b-pagination>
            </div>
            </div>
                        
             </b-overlay>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
    


    
   <!-- <b-overlay :show="show" rounded="sm" no-wrap></b-overlay> -->    
  
  </b-overlay> 
  
     
</div>
</template>
<script>
  export default {
    data() {
      return {
        show_overlay:false, //show or hide full overlay
        show_overlay_occasion:false,//show or hide occation config overlay
        show_overlay_update:false,//show or hide update overlay
        show_overlay_send_message:false,//show or hide send message overlay
        show_overlay_send_calendar_image:false,//show or hide send calendar image overlay
        change_day_gamari:0, 
        allow_send_calendar_to_all_list:true, //in config calendar
        change_gamari_1th_30th_day:false,   //in config calendar
        show_occasions:false,
        message_admins_for_end_gamari_mounth:true,  
        change_config:false, //check change config option in config calendar  
        send_message_id:'',  //id for send message when one member select in send message config
        send_message_to_selected:'all', //one  //select who person send message in send message config
        message_text:'',//in send message config
        send_calendar_image_to_selected:'all', //all,one //select who send calendar image, in send calendar image config
        send_calendar_image_id:'',  //in send calendar image config
        last_backup_date:'never', //never
        restore_from_location:'last_backup',//'upload_sql_file'
        sql_backup_file:null,
        fonts_list:[],
        selected_font_name:'',
        upload_font_file:null,
        new_font_name:'',
        font_config_status:'',//rename,add
        selected_font_name_change:false,
        font_name_valid_status:null,//null,true,false
        update_status:'Not yet Update',
        occasion_type:'shamsi',
        occasion_title:'',
        perPage_occasions:10,
        currentPage_occasions:1,
        occasions:[''],
        occasion_mounth:'',
        occasion_mounths:['1','2','3','4','5','6','7','8','9','10','11','12'],
        
        occasion_day:'',
        occasion_days:['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17',
                       '18','19','20','21','22','23','24','25','26','27','28','29','30','31'],               
      }//return
    },//data()
    
    methods:{
      //**** this method manage occation actions */
      occasion_actions(action){
         var formData=new FormData();
         formData.append('action',action);
         formData.append('day',this.occasion_day);
         formData.append('mounth',this.occasion_mounth);
         formData.append('title',this.occasion_title);
         formData.append('calendar_type',this.occasion_type);
         this.show_overlay_occasion=true;
         axios.post('/occasion_actions',formData)
             .then(respose=>{
               if(action=='get_all'){
                // this.occasion_mounth='';
                // this.occasion_day='';
                // this.occasion_title='';
                 const miladi_mounths=['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
                 const shamsi_mounths=['فروردین','اردیبهشت','خرداد','تیر','مرداد','شهریور','مهر','آبان','آذر','دی','بهمن','اسفند'];
                 const gamari_mounths=['محرم','صفر','ربیع الاول','ربیع الثانی','جمادی الاول','جمادی الثانی',
                                   'رجب','شعبان','رمضان','شوال','ذیقعده','ذیحجه'];
                 var mounth;
                 eval('mounth='+this.occasion_type+'_mounths');
                 this.occasion_mounths=mounth;
                 this.occasions=respose.data;                 
                 }///if(action=='get_all')
                 if(action=='get_one'){
                   this.occasion_title=respose.data;
                 }

               if(respose.data=='ok' && action=='save'){
                 this.occasion_actions('get_all');
                 this.show_messagebox('Successfully','Save Occasion','success'); 
               }
               if(respose.data=='error' && action=='save'){
                 this.show_messagebox('Unsuccessfully','Save Occasion','danger'); 
               }
              if(respose.data=='error') return; //error read data               
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay_occasion=false); 

      },// occation_actions

      /********** this method send message */
      send_message(){
         if(this.send_message_to_selected=='all') id='all'
         else id=this.send_message_id;
        
        var id;
        this.$bvModal.msgBoxConfirm('Are you sure send message to '+id+' ?', {
            title: 'Confirm Send Message',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
                if(value){
                  var formData=new FormData();
                  formData.append('id',id);
                  formData.append('message',this.message_text);
                  this.show_overlay_send_message=true;
                  axios.post('/send_message',formData)
                       .then(respose=>{     
                          if(respose.data=='error'){  
                            this.show_messagebox('Unsuccessfully','Send Message','danger'); 
                            return; //error read data
                          }
                          if(respose.data=='ok'){ 
                            this.show_messagebox('Successfully','Send Message','success');                         
                          }
                       }).catch((error)=>{
                         //console.log(error);
                      }).finally(() => this.show_overlay_send_message=false);
                } 
             })  // }).then(value => {
               .catch(err => { /* console.log(e) */} //msgBoxConfirm
          ) //msgBoxConfirm
       
      },//send_message()

      /******* this method send calendar image */
      send_calendar_image(){
         if(this.send_calendar_image_to_selected=='all') id='all'
         else id=this.send_calendar_image_id;        
        var id;
        this.$bvModal.msgBoxConfirm('Are you sure send Calendar Image to '+id+' ?', {
            title: 'Confirm Send Calendar Image',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
                if(value){
                  var formData=new FormData();
                  formData.append('id',id);
                  this.show_overlay_send_calendar_image=true;
                  axios.post('/send_calendar_image',formData)
                       .then(respose=>{     
                          if(respose.data=='error'){  
                            this.show_messagebox('Unsuccessfully','Send Calendar Image','danger'); 
                            return; //error read data
                          }
                          if(respose.data=='ok'){ 
                            this.show_messagebox('Successfully','Send Calendar Image','success');                         
                          }
                       }).catch((error)=>{
                         //console.log(error);
                      }).finally(() => this.show_overlay_send_calendar_image=false);
                } 
             })  // }).then(value => {
               .catch(err => { /* console.log(e) */} //msgBoxConfirm
          ) //msgBoxConfirm;

      },//send_calendar_image()

      /******* this method update member information */
      update_member_info(){
        this.show_overlay_update=true;
        axios.get( '/update_all_members_info_from_telegram')
            .then(respose=>{     
                if(respose.data=='error'){  
                  this.update_status="Update Unsuccessfully.";  
                  return; //error read data
                 }
                 if(respose.data=='ok'){ 
                   this.update_status="Update Successfully.";                          
                 }
            }).catch((error)=>{
                //console.log(error);
            }).finally(() => this.show_overlay_update=false);

      },//update_member_info()

      /******** this method confirm delete selected form */
      confirm_delete_selected_font(){
        this.$bvModal.msgBoxConfirm('Are you sure delete font: '+this.selected_font_name+' ?', {
            title: 'Confirm Delete',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
                if(value) this.font_actions('delete'); //run font delete
               })  // }).then(value => {
               .catch(err => { /* console.log(e) */} //msgBoxConfirm
          ) //msgBoxConfirm

      },//confirm_delete_selected_font()

      /******** this method confirm rename selected form */
      confirm_rename_selected_font(){
        this.$bvModal.msgBoxConfirm('Are you sure Rename font: '+this.selected_font_name+' to '+
                                     this.new_font_name+' ?', {
            title: 'Confirm Rename',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
                if(value) this.font_actions('rename'); //run font delete
               })  // }).then(value => {
               .catch(err => { /* console.log(e) */} //msgBoxConfirm
          ) //msgBoxConfirm

      },//confirm_delete_selected_font()

      /******* this method manage all fonts actions ****/
      //action: get,delete,upload,check_font_name_vaild,rename,add,
      font_actions(action){
        var formData=new FormData();
        formData.append('action',action);
        formData.append('new_font_name',this.new_font_name);
        formData.append('upload_font_file',this.upload_font_file); 
        formData.append('selected_font_name',this.selected_font_name); 
        if(action=='rename'){
          this.font_config_status='rename';
        }//if(action=='rename')  
        if(action=='add'){
          this.font_config_status='add';
          this.new_font_name=this.upload_font_file.name.substring(0,this.upload_font_file.name.length-4);
          this.font_actions('check_font_name_vaild');
          return;
        }// if(action=='add')
        this.show_overlay=true;
        axios.post( '/font_actions',formData).then(respose=>{
                if(action=='rename'){
                  if(respose.data=='error' || respose.data=='no'){
                    this.show_messagebox('Unsuccessfully Rename','Rename Font','danger'); 
                    return; //error read data
                  }
                  if(respose.data=='ok'){
                    this.font_actions('get');
                    this.show_messagebox('Successfully Rename '+this.selected_font_name+' TO '+this.new_font_name+'.',
                                        'Rename Font','success');
                    this.font_config_status='';
                    return;
                  }                  
                }//if(action=='rename')

                if(action=='delete'){
                  if(respose.data=='error'){
                    this.show_messagebox('Unsuccessfully','Delete Seletced Font','danger'); 
                    return; //error read data
                  }
                  const regex = /Use*/ig;
                  if(regex.exec(respose.data)){
                    this.show_messagebox('Unsuccessfully, Font: '+this.selected_font_name+" "+respose.data,
                                         'Delete Seletced Font','danger'); 
                    return; //error read data
                  }
                  if(respose.data=='ok'){
                    this.font_actions('get');
                    this.show_messagebox('Successfully','Delete Font'+this.selected_font_name,'success');
                    this.font_config_status='';
                    return;
                  }                  
                }//if(action=='delete')
                if(action=='upload'){
                  if(respose.data=='error'){
                    this.show_messagebox('Unsuccessfully','Upload Font File','danger'); 
                    this.upload_font_file=null;
                    this.font_config_status=''; 
                    return; //error read data
                  }
                  if(respose.data=='ok'){
                    this.font_actions('get');
                    this.show_messagebox('Successfully','Upload Font File','success');
                    this.upload_font_file=null;
                    this.font_config_status='';
                    return;
                    }
                }//if(action=='upload')
                if(action=='check_font_name_vaild'){
                  if(respose.data=='error'){
                   this.show_messagebox('Unsuccessfully','Check Font Name','danger');  
                   return; //error read data
                  }
                  if(this.selected_font_name==this.new_font_name && this.font_config_status=='rename'){
                     this.font_name_valid_status=true;
                  }
                  if(respose.data==true) this.font_name_valid_status=true;
                  if(respose.data==false) this.font_name_valid_status=false;
                  return;                         
                }// if(action=='check_font_name_vaild')
               if(action=='get'){
                 if(respose.data=='error'){
                   this.show_messagebox('Unsuccessfully','Get Font List','danger');  
                   return; //error read data
                 } 
                 this.fonts_list=respose.data;
                 this.selected_font_name=this.fonts_list[0];
                 return; 
               }// if(action=='get')        
              //this.show_messagebox('Successfully','Save Calendar Config','success');    
              if(respose.data=='error'){
                //this.show_messagebox('Unsuccessfully','Get Calendar Config','danger') ;  
                return; //error read data
              }
                                                      
            }).catch((error)=>{
                this.upload_font_file=null;
                this.font_config_status=''; 
                //console.log(error);
            }).finally(() => this.show_overlay=false); 
        
      },//font_actions(action)

      /*******this method update members information */
    
      /****** this method manage confige actions *******/
      config_actions(action='get'){//'save','get',
        var formData=new FormData();
        formData.append('change_day_gamari',this.change_day_gamari);
        formData.append('allow_send_calendar_to_all_list',this.allow_send_calendar_to_all_list);
        formData.append('change_gamari_1th_30th_day',this.change_gamari_1th_30th_day);
        formData.append('show_occasions',this.show_occasions);
        formData.append('message_admins_for_end_gamari_mounth',this.message_admins_for_end_gamari_mounth);
        formData.append('action',action);
        this.show_overlay=true;
        axios.post( '/config_actions',formData).then(respose=>{
              if(action=='save'){
                if(respose.data=='error'){  
                  this.show_messagebox('Unsuccessfully','Save Calendar Config','danger') ;  
                  return; //error read data
                 }
                 this.show_messagebox('Successfully','Save Calendar Config','success'); 
                 this.change_config=false;
              } // if(action=='save')  
              if(action=='get'){
                 if(respose.data=='error'){  
                  this.show_messagebox('Unsuccessfully','Get Calendar Config','danger') ;  
                  return; //error read data
                 }
                 this.change_config=false;
                 this.change_day_gamari=respose.data.change_day_gamari;
                 this.allow_send_calendar_to_all_list=respose.data.allow_send_calendar_to_all_list;
                 this.change_gamari_1th_30th_day=respose.data.change_gamari_1th_30th_day;
                 this.show_occasions=respose.data.show_occasions;
                 this.message_admins_for_end_gamari_mounth=respose.data.message_admins_for_end_gamari_mounth;
                 }// if(action=='get')             
                                            
            }).catch((error)=>{
                //console.log(error);
            }).finally(() => this.show_overlay=false);    
      },//config_actions
     
      /********** this method restore databse ***********/
      restore_database(){
        let formData = new FormData();
        /* Add the form data we need to submit*/
        formData.append('restore_type',this.restore_from_location); //'last_backup','upload_sql_file'
        formData.append('backup_file',this.sql_backup_file);
        formData.append('action','restore');
        this.show_overlay=true;
        axios.post( '/database_actions',formData
            ).then(respose=>{     
                if(respose.data=='error'){  
                  this.show_messagebox('Unsuccessfully','Restore Database','danger') ;  
                  return; //error read data
                 }
                 this.show_messagebox('Successfully','Restore Database','success');                            
            }).catch((error)=>{
                //console.log(error);
            }).finally(() => this.show_overlay=false);
            this.sql_backup_file=null;

      },//restore_database()
 
      /********* backup database ***********/
      backup_database(){
         /* Add the form data we need to submit*/
        let formData = new FormData();
        formData.append('action','backup_manually');
        this.show_overlay=true;
        axios.post( '/database_actions',formData
            ).then(respose=>{     
                if(respose.data=='error'){  
                  this.show_messagebox('Unsuccessfully','Backup Database','danger') ;  
                  return; //error read data
                 }
                 this.show_messagebox('Successfully','Backup Database','success');         
                 this.get_last_time_backup();                    
            }).catch((error)=>{
                //console.log(error);
            }).finally(() => this.show_overlay=false);

      },//backup_database()

      /********** download last backup */
      download_last_backup(){
        /* Add the form data we need to submit*/
        let formData = new FormData();
        formData.append('action','download');
        this.show_overlay=true;
        axios.post('/database_actions',formData, { responseType: 'blob' }
          ).then((response) => {
            if(response.data=='error'){  
                  this.show_messagebox('Unsuccessfully','Download Database','danger') ;  
                  return; //error read data
                 }
            const blob = new Blob([response.data], { type: 'application/sql' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob)
            link.download = 'backup_calendar_database.sql'
            link.click()
            URL.revokeObjectURL(link.href)
            }).finally(() => this.show_overlay=false);  
      },//download_last_backup()

      /****** get last time bsckup database */
      get_last_time_backup(){
        /* Add the form data we need to submit*/
        let formData = new FormData();
        formData.append('action','last_backup');
        this.show_overlay=true;
        axios.post( '/database_actions',formData
            ).then(respose=>{     
                if(respose.data=='error' || respose.data=='not exists'){
                  this.last_backup_date='never';
                  return; //error read data
                }
                this.last_backup_date=respose.data;                    
            }).catch((error)=>{
                //console.log(error);
            }).finally(() => this.show_overlay=false);
      },//get_last_time_backup()

      /******* show messagebox */
      show_messagebox(text,title,okVariant){
         this.$bvModal.msgBoxOk(text, {
                         title:title,
                         size: 'sm',
                         buttonSize: 'sm',
                         okVariant: okVariant,
                         headerClass: 'p-2 border-bottom-0',
                         footerClass: 'p-2 border-top-0',
                         centered: true
                         });

      },// show_messagebox(text,title,okVariant)   
       
    },//methods

    mounted(){
      this.get_last_time_backup();
      this.config_actions(); 
      this.font_actions('get');
      this.occasion_actions('get_all');
     // axios
     // .get('https://api.coindesk.com/v1/bpi/currentprice.json')
     // .then(response => {
     //   this.info = response.data.bpi
     // })
     // .catch(error => {
     //   console.log(error)
     //   this.errored = true
     // })
      //.finally(() => this.show = false)
  
    }, //mounted
  } //export default
</script>