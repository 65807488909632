<template>
<div class="container-fluid">
  <b-overlay :show="show_overlay_full" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
 <div class="table-responsive">
   <table class="table table-hover table-bordered table-striped table-sm" style="width:100%">
  <thead>
    <tr>
      <th>#</th>
      <th>Member ID</th>
      <th>Member Title</th>
      <th>Type</th>
      <th>Status</th>
      <th>Join Exp</th>
      <th>Momber Info</th>
      <th>Member Edit</th>
      <th>Member Delete</th>
    </tr>
  </thead>
  <tbody>
   <tr v-for="(member,index) in members" :key="member.ondex" 
      v-show="((currentPage_members-1)*perPage_members<index+1) && (index+1<currentPage_members*perPage_members+1)">
      <th v-text="index+1"></th>
      <td v-text="member.id"></td>
      <td v-text="member.title"></td>
      <td v-text="member.type"></td>
      <td v-text="member.status"></td>
      <td v-text="member.join_exp"></td>
      <td><a href="" @click.prevent="show_member_info(member.id)">Info</a></td>
      <td><a href="" @click.prevent="show_edit_member(member.id)">Edit</a></td>
      <td ><a href="" @click.prevent="delete_member(member.id,member.title)">Delete</a></td>
    </tr>
  </tbody>
</table>
</div>
 <b-pagination
      v-model="currentPage_members"
      :total-rows="members.length"
      :per-page="perPage_members"
      aria-controls="my-table"
       first-number
       last-number 
       pills            
       align="center"
    ></b-pagination>

<!-- Edit Modal  --->
 <b-modal
      v-model="show_edit_modal"
      title="Edit Member"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant="warning"
      footer-text-variant="dark"
    >
      <b-container fluid>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Title:</b-col>
          <b-col v-text="member_title"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Status:</b-col>
          <b-col>
            <b-form-checkbox v-model="member_status"  switch size="sm"
                  @change="change_member_info=true">{{member_status?'Active':'Deactive'}}</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6" >Show Occasions:</b-col>
          <b-col cols="6">
            
            <b-form-checkbox v-model="member_show_occasion_shamsi"  switch size="sm"
                  @change="change_member_info=true">Shamsi</b-form-checkbox>
         
            <b-form-checkbox v-model="member_show_occasion_gamari"  switch size="sm"
                  @change="change_member_info=true">Gamari</b-form-checkbox>
         
            
            <b-form-checkbox v-model="member_show_occasion_miladi"  switch size="sm"
                  @change="change_member_info=true">Miladi</b-form-checkbox>
          </b-col>
        </b-row> 
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Expire Date:</b-col>
          <b-col  cols="6">
            <b-form-input type="date" v-model="member_exp_date" size="sm" @change="change_member_info=true"/>
          </b-col>
        </b-row>
          <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Calendar Image:</b-col>
          <b-col>
             <b-form-select v-model="member_calendar_image_id" size="sm"
                           @change="get_calendar_image_contents(member_calendar_image_id)"
                  >
               <b-form-select-option v-for="calendar_image in calendar_images" :key="calendar_image.ondex"
                                 :value="calendar_image.image_id" v-text="calendar_image.image_name"
                >
               </b-form-select-option>
             </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-1"  v-show="calendar_image_contents!=null">
          <b-col cols="3"></b-col>          
          <b-col >
            <figure class="figure">
          <figcaption class="figure-caption">Calendar Image Preview.</figcaption>
          <b-overlay :show="show_overlay_calendar_image" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
             <img :src="'data:'+calendar_image_type+';base64,'+calendar_image_contents" class="figure-img img-fluid z-depth-1"
                alt="..."  style="width:200px; height:200px;"> 
          </b-overlay>    
        </figure>
          </b-col>
          
        </b-row>
      </b-container>
       <template v-slot:modal-footer>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show_edit_modal=false"
          >
            Close
          </b-button>
          <b-button :disabled="!change_member_info"
            variant="primary"
            size="sm"
            class="float-right"
            @click="update_member_info()"
          >
            Update
          </b-button>
      </template>
    </b-modal>

    <!-- Info Modal  --->
  <b-modal
      v-model="show_info_modal"
      title="Member Information"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant="warning"
      footer-text-variant="dark"
    >
      <b-container fluid>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="5">Title:</b-col>
          <b-col v-text="member_title"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="5">Type:</b-col>
          <b-col v-text="member_type"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded" v-show="member_type=='private'">
          <b-col cols="5">Username:</b-col>
          <b-col v-text="member_username"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="5">Join Date:</b-col>
          <b-col v-text="member_join_on"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded" v-show="member_type!='private'">
          <b-col cols="5">Members Count:</b-col>
          <b-col v-text="members_count"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded" v-show="member_type!='private'">
          <b-col cols="5">Member Description:</b-col>
          <b-col v-text="member_description"></b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded" v-show="member_type!='private'">
          <b-col>
            <b-row> <b-col cols="5">List of Members:</b-col></b-row>
            <b-row>
              <b-col>
                <div class="table-responsive">
                  <table class="table table-hover table-bordered table-striped table-sm" style="font-size:12px;width:100%">
                    <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Username</th>
                    <th>Status</th>
                    </tr>
                  <tr v-for="chat_member in chat_members" :key="chat_member.ondex">
                    <td v-text="chat_member.member_id"></td>
                    <td v-text="chat_member.title"></td>
                    <td v-text="chat_member.username"></td>
                    <td v-text="chat_member.status"></td> 
                  </tr>
                </table>
                </div>            
              </b-col>
           </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-1"  v-show="member_image_contents!=''">
          <b-col cols="3"></b-col>          
          <b-col >
            <figure class="figure"  style="width: 160px;height:160px">
          <figcaption class="figure-caption">Member Image Preview.</figcaption>          
             <img :src="'data:image/png;base64,'+member_image_contents" class="figure-img img-fluid z-depth-1"
                alt="..." style="width: 400px">    
        </figure>
          </b-col>
          
        </b-row>
      </b-container>
       <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show_info_modal=false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
</b-overlay>
</div>
</template>
<script>
  export default {
    data() {
        return{
          show_overlay_full:false, //show overlay for full page
          show_overlay_calendar_image:false, //show overlay for calendar image
          calendar_image_type:null, 
          calendar_image_contents:null,
          currentPage_members:1, //current page number for pagination
          perPage_members:11, //per page in table
          show_info_modal:false,
          change_member_info:false, //check for change member info in modal edit
          show_edit_modal:false,
          members:[''], //contents of all members
          member_id:null, //
          member_type:null,
          chat_members:[''], //list of member in chat selection
          members_count:null,//number of member in chat seletcion
          member_description:null,
          member_title:null,
          member_username:null,
          member_status:true,
          member_join_on:null,
          member_image_contents:null,
          member_exp_date:null,
          member_calendar_image_id:'',
          member_show_occasion_shamsi:true, 
          member_show_occasion_miladi:true, 
          member_show_occasion_gamari:true, 
          calendar_images:[''],
       }
      },//data()     
    methods:{

      /****** this method show member information */
      show_member_info(id){ 
        this.show_overlay_full=true;
            axios.get('/getMemberInfo/'+id)
                .then(respose=>{ 
                  this.show_info_modal=true;
                  var member_info=respose.data;
                  this.member_type=member_info.type;
                  this.member_username=member_info.username;
                  this.member_join_on=member_info.join_on;
                  this.members_count=member_info.chatMembersCount;
                  this.chat_members=member_info.chatMembers;
                  this.member_description=member_info.description;
                  this.member_title=member_info.title; 
                  this.member_image_contents=member_info.photo;               
                  //console.log(this.photo);
                    }).catch(function(error){
                        console.log(error);
                 }).finally(() => this.show_overlay_full=false); 
       },//show_info_member

       //**** this method update member information */
        update_member_info(){
          this.show_overlay_full=true;
          if(!Date.parse(this.member_exp_date)){
            this.show_messagebox('Expire Date Invalid','Input ERROR','danger'); 
            return;
          }
          var formData = new FormData();
          formData.append('id',this.member_id);
          formData.append('calendar_image_id',this.member_calendar_image_id);
          formData.append('status',(this.member_status?'Active':'Deactive'));
          formData.append('join_exp',this.member_exp_date);
          formData.append('show_occasion_shamsi',this.member_show_occasion_shamsi);
          formData.append('show_occasion_gamari',this.member_show_occasion_gamari);
          formData.append('show_occasion_miladi',this.member_show_occasion_miladi);
          axios.post('/updateMemberInformation',formData)
               .then(respose=>{
                 if(respose.data=='error'){
                   this.show_messagebox('Unsuccessfully','Update Member Information','danger'); 
                    return;                   
                 }
                 this.show_edit_modal=false; 
                 this.show_messagebox('Successfully','Update Member Information','success');
                 this.change_member_info=false;
                 
                 this.loadListMembers(); 
                }).catch(function(error){
                    //console.log(error);
             }).finally(function(){
                this.show_overlay_full=false
             });  
        },//update_member_info()

      /***** get calendar_image_contents */
      get_calendar_image_contents(image_id){
        if(this.show_edit_modal) this.change_member_info=true;
        this.show_overlay_calendar_image=true;
        this.calendar_image_type=null;
        this.calendar_image_contents=null;  
        axios.get('/getAllInfoCalendarImage/'+image_id)
             .then(respose=>{
                  if(respose.data=='error'){
                    this.show_messagebox('Unsuccessfully','Get Calendar Image','danger'); 
                    return;
                  }
                  this.calendar_image_type=respose.data.image_type;
                  this.calendar_image_contents=respose.data.image_contents;                    
                  
                    }).catch(function(error){
                        //console.log(error);
                 }).finally(() => this.show_overlay_calendar_image=false); 

      },//get_calendar_image_contents(image_id){

      /***** get all calendar image names and id */
      get_all_calendar_image_ids_names(){
        axios.get('/getAllImagesNames_ids')
                          .then(respose=>{
                            this.calendar_images=respose.data;                                      
                           }).catch(function(error){
                             //console.log(error);
                            }); 
      },//get_all_calendar_image_ids_names(){

      /******** this method show member info for edit */
      show_edit_member(id){
        this.member_id=id;
        this.show_overlay_full=true;
        this.change_member_info=false;
        axios.get('/getMemberInfoEdit/'+id)
             .then(respose=>{
                  var member=respose.data;
                  if(member=='error'){
                    this.show_messagebox('Unsuccessfully','Get Member Informations','danger'); 
                    return;
                  } 
                    this.member_exp_date=member.join_exp;
                    this.member_title=member.title;
                    this.member_calendar_image_id=member.calendar_image_id;
                    this.member_status=member.status=='Active'?true:false;
                    this.member_show_occasion_shamsi=member.show_occasion_shamsi=='true'?true:false;
                    this.member_show_occasion_miladi=member.show_occasion_miladi=='true'?true:false;
                    this.member_show_occasion_gamari=member.show_occasion_gamari=='true'?true:false;
                     this.get_all_calendar_image_ids_names();
                     this.get_calendar_image_contents(this.member_calendar_image_id) 
                    this.show_edit_modal=true;
                    }).catch(function(error){
                        //console.log(error);
                 }).finally(() => this.show_overlay_full=false);     
      },//show_edit_member(id)
        
        //load list of member on table 
        loadListMembers(){
          this.show_overlay_full=true;
           axios.get('/getAllMemberList')
               .then(respose=>{
                    this.members=respose.data;
                     //console.log(this.members);
                     }).catch(function(error){
                       //console.log(error);        
                 }).finally(() => this.show_overlay_full=false);

        },

        /**** this method delete member */
        delete_member(id,title){ 
           this.$bvModal.msgBoxConfirm('Are you sure Delete Member: '+title+'?', {
            title: 'Confirm Delete',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
              if(value){
                 //clear errors
                 this.show_overlay_full=true;
                 axios.get('/delete_member/'+id)
                      .then(respose=>{
                        if(respose.data=='error'){
                          this.show_messagebox('Unsuccessfully','Delete Member','danger'); 
                        }
                         if(respose.data=='ok'){
                          this.show_messagebox('Successfully','Delete Member','success'); 
                          this.loadListMembers(); 
                         }                      
                        }).catch(function(error){
                            //console.log(error);
                        }).finally(() => this.show_overlay_full=false); 
                       } //if(value){
                   })  // }).then(value => { 
        },//delete_member(id,title){
        
        /**** this method show message box */
        show_messagebox(text,title,okVariant){
         this.$bvModal.msgBoxOk(text, {
                         title:title,
                         size: 'sm',
                         buttonSize: 'sm',
                         okVariant: okVariant,
                         headerClass: 'p-2 border-bottom-0',
                         footerClass: 'p-2 border-top-0',
                         centered: true
                         });
      },// show_messagebox(text,title,okVariant) 
       
     },//methods
    mounted(){
       this.loadListMembers();
    },//mounted

  }
</script>

