//require('./bootstrap'); 

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import { BootstrapVue,OverlayPlugin} from 'bootstrap-vue'
//import {OverlayPlugin} from 'bootstrap-vue'


// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
Vue.use(OverlayPlugin)


//window._ = require('lodash');
window.axios = require('axios');
//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
 

import my_footer from './components/footer';
import my_header from './components/header';
import home from './components/home';
import calendar_images from './components/calendar_images';
import calendar_configs from './components/calendar_configs';
import calendar_reports from './components/calendar_reports';
import members from './components/members';
import login from './components/login';
import users from './components/users';
import profile from './components/profile';
 
new Vue({
    el:"#app",
    components: { 
        my_header,my_footer,home,login,profile,users,
        members,calendar_images,calendar_configs,calendar_reports,
    }, 
});

