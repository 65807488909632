<template>
<div class="container-fluid">
   <b-overlay :show="show_overlay" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
      <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-errors href="#">Errors Reports</a>
      </b-card-header>
      <b-collapse id="accordion-errors" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
              <div class="row">
                <div class="col-sm-12">
                <b-card-text v-if="calendar_errors==''">no error found</b-card-text>
                <b-card-text v-else >
                  <pre v-text="calendar_errors"></pre>
                  <b-button variant="primary" size="sm" @click="clear_calendar_errors">Clear Errors</b-button>
                </b-card-text>
                </div>
            </div> 
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
    
      <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-logs href="#">Logs Reports</a>
      </b-card-header>
      <b-collapse id="accordion-logs" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text v-if="calendar_logs==''">no log found</b-card-text>
          <b-card-text v-else >
            <div>
              <b-table striped hover small 
                       responsive
                       :items="calendar_logs" 
                       :per-page="perPage_logs"
                       :current-page="currentPage_logs"
                       id="calendar_logs_table">
              </b-table>
              <b-pagination  
                  v-model="currentPage_logs"
                  :total-rows="calendar_logs.length"
                  :per-page="perPage_logs"
                  aria-controls="calendar_logs_table"
                  first-number
                  last-number 
                  pills            
                  align="center"
              ></b-pagination>
            </div>
            <b-button variant="primary" size="sm" @click="clear_calendar_logs">Clear logs</b-button>
        </b-card-text> 
      </b-card-body>
      </b-collapse>
    </b-card>
    
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <a class="card-link" v-b-toggle.accordion-histories href="#">Histories Send Reports</a>
      </b-card-header>
      <b-collapse id="accordion-histories" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text v-if="send_histories==''">no send history found</b-card-text>
          <b-card-text v-else >
            <div>
              <b-table  striped hover small 
                        responsive
                        :items="send_histories"                      
                        :per-page="perPage_histories"
                        :current-page="currentPage_histories"
                        id="send_histories_table">
             </b-table>
             <b-pagination  
                 v-model="currentPage_histories"
                :total-rows="send_histories.length"
                :per-page="perPage_histories"
                aria-controls="send_histories_table"
                first-number
                last-number 
                pills            
                align="center"
              ></b-pagination>
            </div>
            <b-button variant="primary" size="sm" @click="clear_send_histories">Clear histories</b-button>
        </b-card-text>            
      </b-card-body>
    </b-collapse>
   </b-card>
 </b-overlay> 
</div>     
</template>
<script>
  export default {
    data() {
      return {
        show_overlay:false, //show or hide overlay
        currentPage_logs:1, 
        perPage_logs:8,
        currentPage_histories:1,
        perPage_histories:8,
        image_name:'',
        calendar_errors:'',
        calendar_logs:'',
        send_histories:'',
        active_tab:'Logs',
      }//return
    },//data()
    
    methods:{
      
      /*****this method cancel update **/
      cancel_update(){
        this.changed_image_properties=false;
        this.browsed_image=[]; //clear input tag 
        this.get_all_image_list(); //refresh image list
      },//cancel_update()

      /******* this method clear calendar errors */
      clear_calendar_errors(){
        this.$bvModal.msgBoxConfirm('Are you sure clear calendar errors?', {
            title: 'Confirm Clear',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
              if(value){
                 //clear errors
                 this.show_overlay=true;
                 axios.get('/clear_all_errors')
                      .then(respose=>{
                           if(respose.data=='error') return; //error read data  
                           if(respose.data==1){//Clear successfuly
                             this.calendar_errors=''; //refresh calendar errors
                             this.$bvModal.msgBoxOk('successfully clear errors', {
                               title: 'Information',
                               size: 'sm',
                               buttonSize: 'sm',
                               okVariant: 'success',
                               headerClass: 'p-2 border-bottom-0',
                               footerClass: 'p-2 border-top-0',
                               centered: true
                               });
                         } // if(respose.data==1)                
                         else{ //check for other reason
                             this.$bvModal.msgBoxOk('Unsuccessfully clear errors ', {
                                title: 'Information',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'danger',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                              });
                            }//else
                          }).catch(function(error){
                              //console.log(error);
                             }).finally(() => this.show_overlay=false); 
                       } //if(value){
                   })  // }).then(value => { 

      },//clear_calendar_errors()

      /******* this method clear calendar logs */
      clear_calendar_logs(){
        this.$bvModal.msgBoxConfirm('Are you sure clear calendar logs?', {
            title: 'Confirm Clear',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
              if(value){
                 //clear errors
                 this.show_overlay=true;
                 axios.get('/clear_all_calendar_logs')
                      .then(respose=>{
                           if(respose.data=='error') return; //error read data  
                           if(respose.data==1){//Clear successfuly
                             this.calendar_logs=''; //refresh calendar logs
                             this.$bvModal.msgBoxOk('successfully clear logs', {
                               title: 'Information',
                               size: 'sm',
                               buttonSize: 'sm',
                               okVariant: 'success',
                               headerClass: 'p-2 border-bottom-0',
                               footerClass: 'p-2 border-top-0',
                               centered: true
                               });
                         } // if(respose.data==1)                
                         else{ //check for other reason
                             this.$bvModal.msgBoxOk('Unsuccessfully clear logs ', {
                                title: 'Information',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'danger',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                              });
                            }//else
                          }).catch(function(error){
                              //console.log(error);
                             }).finally(() => this.show_overlay=false); 
                       } //if(value){
                   })  // }).then(value => { 

      },//clear_calendar_logs()

      /******* this method clear send histories */
      clear_send_histories(){
        this.$bvModal.msgBoxConfirm('Are you sure clear send histories?', {
            title: 'Confirm Clear',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
              if(value){
                 //clear errors
                 this.show_overlay=true;
                 axios.get('/clear_all_send_histories')
                      .then(respose=>{
                           if(respose.data=='error') return; //error read data  
                           if(respose.data==1){//Clear successfuly
                             this.send_histories=''; //refresh send histories
                             this.$bvModal.msgBoxOk('successfully send histories', {
                               title: 'Information',
                               size: 'sm',
                               buttonSize: 'sm',
                               okVariant: 'success',
                               headerClass: 'p-2 border-bottom-0',
                               footerClass: 'p-2 border-top-0',
                               centered: true
                               });
                         } // if(respose.data==1)                
                         else{ //check for other reason
                             this.$bvModal.msgBoxOk('Unsuccessfully clear send histories ', {
                                title: 'Information',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'danger',
                                headerClass: 'p-2 border-bottom-0',
                                footerClass: 'p-2 border-top-0',
                                centered: true
                              });
                            }//else
                          }).catch(function(error){
                              //console.log(error);
                             }).finally(() => this.show_overlay=false); 
                       } //if(value){
                   })  // }).then(value => { 

      },// clear_send_histories() 
       
    },//methods

    mounted(){
      this.show_overlay=true;
      axios.get('/get_all_send_histories')
           .then(respose=>{    
              if(respose.data=='error') return; //error read data               
              this.send_histories=respose.data; 
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false);  
      this.show_overlay=true;           
      axios.get('/get_all_calendar_logs')
           .then(respose=>{    
              if(respose.data=='error') return; //error read data               
              this.calendar_logs=respose.data; 
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false); 
      this.show_overlay=true;          
      axios.get('/get_all_errors')
           .then(respose=>{    
              if(respose.data=='error') return; //error read data             
              this.calendar_errors=respose.data; 
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false);      
    }, //mounted
  } //export default
</script>