lv<template>
  <div class="container-fluid">
    <b-overlay :show="show_overlay" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
      <br>
      <b-row>
        <b-col> 
          <b-row style="text-align:center;">
            <b-col><h3> User Profile</h3></b-col>  
          </b-row>
          <br>
          <b-row style="text-align:center;">
            <b-col>
                <b-form-file ref="new_image" accept=".jpg, .jpeg, .png, .gif" style="display: none"
                              v-model="new_profile_image" @change.native="change_user_profile_image()"/>
                <a href="" @click.prevent="$refs.new_image.$el.childNodes[0].click()">
                <b-avatar v-if="profile_image" :src="'data:image/jpeg;base64,'+profile_image" 
                         size="10rem">
                </b-avatar> 
                <b-avatar v-if="!profile_image" variant="primary" :text="first_name.substring(0,2)"  size="10rem"></b-avatar>
                </a>
            </b-col>  
          </b-row>
          <br>
          <b-row>
             <b-col sm="2" md="3" xl="4"></b-col>
             <b-col>
               <b-card>
                 <b-row>
                   <b-col sm="4">                
                     <b>ID:</b>
                   </b-col>
                   <b-col>                
                     <span v-text="id"></span>                  
                   </b-col>     
                 </b-row>
                  <b-row>
                   <b-col sm="4">                
                     <b>First Name:</b>
                   </b-col>
                   <b-col>                
                     <span v-text="first_name"></span>                  
                   </b-col>     
                 </b-row>
                  <b-row>
                   <b-col sm="4">                
                     <b>Lase Name:</b>
                   </b-col>
                   <b-col>                
                     <span v-text="last_name"></span>                  
                   </b-col>     
                 </b-row>
                  <b-row>
                   <b-col sm="4">                
                     <b>Username:</b>
                   </b-col>
                   <b-col>                
                     <span v-text="username"></span>                  
                   </b-col>     
                 </b-row>
                  <b-row>
                   <b-col sm="4">                
                     <b>E-mail:</b>
                   </b-col>
                   <b-col>                
                     <span v-text="email"></span>                  
                   </b-col>     
                 </b-row>
                 <b-row>
                   <b-col sm="4">                
                     <b>Status:</b>
                   </b-col>
                   <b-col>                
                     <span v-text="status"></span>                  
                   </b-col>     
                 </b-row>
                  <b-row>
                   <b-col sm="4">                
                     <b>Last Login:</b>
                   </b-col>
                   <b-col>                
                     <span v-text="last_login"></span>                  
                   </b-col>     
                 </b-row>                                 
            </b-card>
          </b-col>
          <b-col sm="2" md="3" xl="4"></b-col>
            </b-row>              
            <br>
            <b-row style="text-align:center;">
              <b-col>   
                <b-button size="sm" type="button" @click="update_profile" variant="primary">
                  Update Profile
                </b-button>
                <b-button size="sm" type="button" @click="show_change_email_modal=true" variant="primary">
                  Change Email
                </b-button> 
                <b-button size="sm" type="button" @click="show_change_password_modal=true" variant="primary">
                  Change Password
                </b-button>                                        
              </b-col> 
            </b-row>
          </b-col>
    </b-row>
  <!-- Change Email Modal  --->
 <b-modal
      v-model="show_change_email_modal"
      title="Change Email"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant="warning"
      footer-text-variant="dark"
    >
      <b-container fluid>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">New Email</b-col>
          <b-col  cols="6">
            <b-form-input type="email" v-model="new_email" size="sm"/>
          </b-col>
        </b-row>
      </b-container>
       <template v-slot:modal-footer>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show_change_email_modal=false"
          >
            Close
          </b-button>
          <b-button :disabled="new_email==email"
            variant="primary"
            size="sm"
            class="float-right"
            @click="change_email()"
          >
            Save
          </b-button>
      </template>
    </b-modal>
    <!-- Change Password Modal  --->
 <b-modal
      v-model="show_change_password_modal"
      title="Change Password"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant="warning"
      footer-text-variant="dark"
    >
      <b-container fluid>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">New Password</b-col>
          <b-col  cols="6">
            <b-form-input type="password" v-model="new_password" size="sm"/>
          </b-col>
        </b-row>
        <b-row class="mb-1 shadow-sm bg-white rounded">
          <b-col cols="6">Re-new Password</b-col>
          <b-col  cols="6">
            <b-form-input type="password" v-model="re_new_password" size="sm"/>
          </b-col>
        </b-row>
      </b-container>
       <template v-slot:modal-footer>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show_change_password_modal=false"
          >
            Close
          </b-button>
          <b-button :disabled="re_new_password!=new_password || new_password.length<1"
            variant="primary"
            size="sm"
            class="float-right"
            @click="change_password()"
          >
            Save
          </b-button>
      </template>
    </b-modal>

    </b-overlay>
  </div>
</template>

<script>
  export default {
    data() {
      return {
          show_overlay:false,
          show_change_email_modal:false,
          show_change_password_modal:false,
          new_profile_image:null,
          username:'',
          id:'',
          first_name:'',
          last_name:'',
          email:'',
          status:'',
          profile_image:'',
          last_login:'',
          new_password:'',
          re_new_password:'',
          new_email:'',
      }
    },
    methods: {
      
      //this function change_user_profile_image
      change_user_profile_image(){
        var formData=new FormData();
        formData.append('new_profile_image',this.new_profile_image);
        this.show_overlay=true;
        axios.post('/change_user_profile_image',formData).then(respose=>{ 
          if(respose.data=='ok'){
            this.show_messagebox('Successfully','Change Profile Image','success');
            this.get_user_information();
            return;
          }
          this.show_messagebox('Unsuccessfully','Change Profile Image','danger');
        }).catch(function(error){
               //console.log(error);
           }).finally(() => this.show_overlay=false);
            this.new_profile_image=null;
      },//change_user_profile_image()

      get_user_information(){
        this.show_overlay=true;
            axios.post('/get_user_information').then(respose=>{   
                var user=respose.data; 
                this.id=user.id;
                this.first_name=user.first_name;
                this.last_name=user.last_name;
                this.username=user.username;
                this.email=user.email;
                this.new_email=this.email;
                this.last_login=user.last_login;
                this.status=user.status;             
                this.profile_image=user.profile_image;
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false); 
      },//get_user_information()

      update_profile(){
        this.show_overlay=true;
            axios.post('/update_user_profile').then(respose=>{   
                if(respose.data=='ok'){
                  this.get_user_information();
                  this.show_messagebox('Successfully','Update User Profile','success');
                  return;
                }
                this.show_messagebox('Unsuccessfully','Update User Profile','danger');
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false); 
      },//update_profile()
      change_email(){
        this.show_change_email_modal=false;
        var formData=new FormData();
        this.show_overlay=true;
        formData.append('new_email',this.new_email),
        axios.post('/change_user_email',formData).then(respose=>{ 
          if(respose.data=='ok'){
            this.show_messagebox('Successfully','Change Email','success');
            this.get_user_information();
            return;
          }
          this.show_messagebox('Unsuccessfully','Change Email','danger');
        }).catch(function(error){
               //console.log(error);
           }).finally(() => this.show_overlay=false); 
      },//change_email()

      change_password(){
        this.show_change_password_modal=false;
        var formData=new FormData();
        this.show_overlay=true;
        formData.append('new_password',this.new_password),
        this.new_password='';
        this.re_new_password='';
        axios.post('/change_user_password',formData).then(respose=>{ 
          if(respose.data=='ok'){
            this.show_messagebox('Successfully','Change Password','success');
            this.get_user_information();
            return;
          }
          this.show_messagebox('Unsuccessfully','Change Password','danger');
        }).catch(function(error){
               //console.log(error);
           }).finally(() => this.show_overlay=false); 

      },//change_password()

       /**** this method show message box */
      show_messagebox(text,title,okVariant){
        this.$bvModal.msgBoxOk(text, {
                         title:title,
                         size: 'sm',
                         buttonSize: 'sm',
                         okVariant: okVariant,
                         headerClass: 'p-2 border-bottom-0',
                         footerClass: 'p-2 border-top-0',
                         centered: true
                         });
      },// show_messagebox(text,title,okVariant) 
          
    },//methods
    mounted(){
      this.get_user_information();
    }
  }
</script>