<template>

  <!-- Footer -->
  <div>
    <br>
    <br>
  <b-navbar toggleable="xl" type="dark" variant="primary" :fixed="fixed" >
      <b-navbar-nav class="m1-auto">
        <b-nav-item href="#" disabled="">Copyright &copy; <span v-text="year"/> <span v-text="host" />.</b-nav-item>
      </b-navbar-nav>
   
  </b-navbar>
</div>
<!-- Footer -->
</template>
<script>
  export default{
  data(){
    return{
      year:new Date().toISOString().slice(0,4),
      host:window.location.hostname,
      fixed:null,
      }// return
    },//data()
    methods:{
    },//methods
    mounted(){ 
      this.fixed=document.documentElement.scrollHeight>document.documentElement.clientHeight?'end':'bottom'   
      
    },
  }//export default
</script>