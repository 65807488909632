<template>
<div>
  <b-navbar  toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand href="/home">Calendar Bot</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-show="logged_in">
        <b-nav-item href="/home" :active="active_menu=='/home'">
             Home
        </b-nav-item>
        <b-nav-item href="/members" :active="active_menu=='/members'">
           Members
        </b-nav-item>
        <b-nav-item href="/calendar_images" :active="active_menu=='/calendar_images'">
            Images
        </b-nav-item>
        <b-nav-item  href="/calendar_configs" :active="active_menu=='/calendar_configs'">
            Configs
        </b-nav-item>
        <b-nav-item  href="/calendar_reports" :active="active_menu=='/calendar_reports'">
            Reports
        </b-nav-item>
        <b-nav-item  href="/users" :active="active_menu=='/users'">
            Users
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-show="!logged_in">
        <b-nav-item href="/home" :active="active_menu=='/home'">
             Home
        </b-nav-item>
      </b-navbar-nav>  
      <b-navbar-nav class="ml-auto" >
        <b-nav-item v-show="!logged_in" href="/login" :active="active_menu=='/login'">
            Login
        </b-nav-item>
        <b-nav-item v-show="logged_in" href="/profile" :active="active_menu=='/profile'">{{title}}</b-nav-item>
        <b-nav-item-dropdown v-show="logged_in" right>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <b-avatar v-if="profile_image" :src="'data:image/jpeg;base64,'+profile_image"/>
            <b-avatar v-if="!profile_image" variant="info" :text="title.substring(0,2)"/>
          </template>
          <b-dropdown-item href="/profile">Profile</b-dropdown-item>
          <b-dropdown-item href="/logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div> 
</template>
<script>
export default{
  data(){
    return{
      active_menu:window.location.pathname,
      logged_in:false,
      title:'',
      profile_image:'',
      }// return
    },//data()

    methods:{
      check_logged_in(){
         axios.post('/check_logged_in')
                      .then(respose=>{
                           //if(respose.data=='error') return; //error read data 
                           var data=respose.data;
                           if(data.id){
                             this.logged_in=true;  
                             this.title=data.last_name?data.first_name+' '+data.last_name:data.first_name;
                             this.profile_image=data.profile_image;
                           }else this.logged_in=false;               
                           
                          }).catch(function(error){
                              //console.log(error);
                             });
      },//check_logged_in()
    },// methods
    mounted(){
      this.check_logged_in();     
    }
  }//export default
</script>