<template>
  <div class="container-fluid">
    <b-overlay :show="show_overlay" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
    <br>
    <b-row>
        <b-col sm="2" md="3"></b-col>
        <b-col sm="8" md="5">  
            <!-- page_state='login' -->
            <b-card  header="Login Calendar Bot" v-show="page_state=='login'">
                <b-form @submit="auth_login" method="post">
                    <b-row>
                        <b-col sm='4'></b-col> 
                         <b-col> 
                            <label style="color:red;" 
                              v-text="message"
                            />
                        </b-col>    
                    </b-row>
                    <b-row>
                        <b-col sm="4">                
                            <label for="username">Username:</label>
                        </b-col>
                        <b-col>                
                            <b-form-input
                                name="username"
                                v-model="username" 
                                required
                                placeholder="Enter Username"
                            ></b-form-input>                   
                        </b-col>     
                    </b-row>
                    <br>
                    <b-row>
                        <b-col sm="4">   
                           <label for="password">Password:</label>
                        </b-col>
                        <b-col>   
                            <b-form-input
                                name="password"
                                v-model="password"
                                type="password"
                                required
                                placeholder="Enter Password"
                            ></b-form-input>                   
                        </b-col>
                    </b-row>
                    <br>
                    <b-row v-show="login_state=='error_authenticate' || login_state=='error_recaptcha' ">
                        <b-col sm="4"> </b-col>
                        <b-col>    
                            <img :src="'data:image/png;base64,'+recaptcha_image_contents" 
                                  style="width:110px;height:35px;">   
                            <b-button type="button" @click="get_recaptcha_image('true')"
                                      variant="light" size="sm">
                                <span style=" font-size:15px; font-weight: bold;">&#8635;</span>
                            </b-button> 
                        </b-col>
                    </b-row>
                      <b-row v-show="login_state=='error_authenticate' || login_state=='error_recaptcha' ">
                        <b-col sm="4">   
                           <label for="security_code">security Code:</label>
                        </b-col>
                        <b-col cols="6" sm="4">   
                            <b-form-input
                                id="security_code"
                                name="security_code"
                                v-model="recaptcha_code"
                            ></b-form-input>                   
                        </b-col>
                        
                    </b-row>
                    <b-row>
                        <b-col sm="4"></b-col>   
                        <b-col>   
                            <b-form-checkbox v-model="remember_me" value="true"
                                unchecked-value="false">Remember Me</b-form-checkbox>                                        
                        </b-col>
                    </b-row>
                    <br>
                    <b-row>
                        <b-col sm="4"> </b-col>
                        <b-col>   
                           <b-button type="submit" variant="primary">Login</b-button>
                           <b-button type="button" 
                                     @click="page_state='forget_password_username';get_recaptcha_image('true')" 
                                     variant="primary">Forget Password</b-button>                                       
                        </b-col> 
                    </b-row>
                </b-form>
            </b-card>

            <!-- page_state='forget_password_username' -->
             <b-card  header="Recovery Password" v-show="page_state=='forget_password_username'">
                <b-form @submit="send_recovery_code" method="post">
                    <b-row>
                        <b-col sm='4'></b-col> 
                        <b-col> 
                            <label style="color:red;" 
                              v-text="message"
                            />
                        </b-col>     
                    </b-row>
                    <b-row>
                        <b-col sm="4">                
                            <label for="username">Username:</label>
                        </b-col>
                        <b-col>                
                            <b-form-input
                                name="username"
                                v-model="username" 
                                required
                                placeholder="Enter Username"
                            ></b-form-input>                   
                        </b-col>     
                    </b-row>
                    <br>
                     <b-row>
                        <b-col sm="4">                
                            <label for="username">Send Code To:</label>
                        </b-col>
                        <b-col>                
                             <b-form-group>
                                 <b-form-radio-group
                                    v-model="send_code_location"
                                    :options="send_code_locations"
                                    buttons
                                    button-variant="outline-info"
                                    size="sm"
                                    name="radio-btn-outline"
                                ></b-form-radio-group>
                            </b-form-group>         
                        </b-col>     
                    </b-row>
                    <br>
                    <b-row>
                        <b-col sm="4"> </b-col>
                        <b-col>    
                            <img :src="'data:image/png;base64,'+recaptcha_image_contents" 
                                  style="width:110px;height:35px;">   
                            <b-button type="button" @click="get_recaptcha_image('true')"
                                      variant="light" size="sm">
                                <span style=" font-size:15px; font-weight: bold;">&#8635;</span>
                            </b-button> 
                        </b-col>
                    </b-row>
                      <b-row>
                        <b-col sm="4">   
                           <label for="security_code">security Code:</label>
                        </b-col>
                        <b-col cols="6" sm="4">   
                            <b-form-input
                                name="security_code"
                                v-model="recaptcha_code"
                            ></b-form-input>                   
                        </b-col>
                        
                    </b-row>
                    <br>
                    <b-row>
                        <b-col sm="4"> </b-col>
                        <b-col>
                           <b-button type="button" @click="page_state='login'" variant="primary">Cancel</b-button>    
                           <b-button type="submit" variant="primary">Next</b-button>
                                                                 
                        </b-col> 
                    </b-row>
                </b-form>
            </b-card>

            <!-- page_state='forget_password_set_new_password' -->
             <b-card  header="Set New Password" v-show="page_state=='forget_password_set_new_password'">
                <b-form @submit="change_password" method="post">
                    <b-row>
                        <b-col sm='4'></b-col> 
                        <b-col> 
                            <label style="color:red;" 
                              v-text="message"
                            />
                        </b-col>     
                    </b-row>
                    <b-row>
                        <b-col sm="4">                
                            <label>Recovery Code:</label>
                        </b-col>
                        <b-col>                
                            <b-form-input
                                name="recovery_code"
                                v-model="recovery_code" 
                                required
                                placeholder="Enter Recovery Code"
                            ></b-form-input>                   
                        </b-col>     
                    </b-row>
                    <br>
                     <b-row>
                        <b-col sm="4">   
                           <label>New Password:</label>
                        </b-col>
                        <b-col>   
                            <b-form-input
                                name="new_password"
                                v-model="new_password"
                                type="password"
                                required
                                placeholder="Enter New Password"
                            ></b-form-input>                   
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">   
                           <label>Renew Password:</label>
                        </b-col>
                        <b-col>   
                            <b-form-input
                                name="renew_password"
                                v-model="renew_password"
                                type="password"
                                required
                                placeholder="Enter Renew Password"
                            ></b-form-input>                   
                        </b-col>
                    </b-row>
                    <br>
                    <b-row>
                        <b-col sm="4"> </b-col>
                        <b-col>
                           <b-button type="button" @click="page_state='login'" variant="primary">Cancel</b-button>    
                           <b-button type="submit" variant="primary" :disabled="new_password!=renew_password">
                               Change Password
                           </b-button>                                       
                        </b-col> 
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>

<script>
  export default {
    data() {
      return {
          show_overlay:false,
          username:'',
          remember_me:'false',
          password: '',
          new_password:'',
          renew_password:'',
          login_state:'',
          page_state:'login',
          message:'',
          send_code_locations:['Telegram','Email'],
          send_code_location:'Telegram',
          recaptcha_image_contents:'',
          recaptcha_code:'',
          recovery_code:'',
      }
    },
    methods: {
        /** change_password */
        change_password(evt){
            evt.preventDefault();
            this.show_overlay=true;
            var formData=new FormData();
            formData.append('new_password',this.new_password);
            formData.append('recovery_code',this.recovery_code);
            axios.post('/recovery_password',formData).then(respose=>{
                var data=respose.data; //send_ok, error_username, error_recaptcha, error   
                console.log(data)            
                if(data=='recovery_ok'){
                    //goto login form
                    this.message='recovey password successfully.';
                    this.page_state='login';                    
                    return; 
                }
                if(data=='error') this.message='error  recovery password.';

                if(data=='error_recovery_code') this.message='recovery code invalid.';
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false);
        },//change_password()

        /****  send_recovery_code */
        send_recovery_code(evt){
            evt.preventDefault();
            this.show_overlay=true;
            var formData=new FormData();
            formData.append('username',this.username);
            formData.append('send_code_location',this.send_code_location);
            formData.append('recaptcha_code',this.recaptcha_code);
            axios.post('/send_recovery_code',formData).then(respose=>{
                var data=respose.data; //send_ok, error_username, error_recaptcha, error             
                if(data=='send_ok'){
                    //goto set password
                    this.message='';
                    this.page_state='forget_password_set_new_password';                    
                    return; 
                }
                if(data=='error_recaptcha') 
                     this.message='security code invalid.';

                if(data=='error_username')      
                     this.message='username invalid.';
            
                if(data=='error')
                   this.message='error send recovery code.';
               this.get_recaptcha_image('true');
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false); 
        },//send_recovery_code()

        auth_login(evt){
            evt.preventDefault();
            this.show_overlay=true;
            var formData=new FormData();
            formData.append('username',this.username);
            formData.append('password',this.password);
            formData.append('remember_me',this.remember_me);
            formData.append('recaptcha_code',this.recaptcha_code);
            axios.post('/auth_login',formData).then(respose=>{
                var data=respose.data;   
                this.login_state=data; //authenticated, error_authenticate, error_recaptcha            
                if(data=='authenticated'){
                    this.page_state='';
                    window.location = '/profile';
                    return; 
                }
                if(data=='error_authenticate') this.message='username or password invalid.';
                if(data=='error_recaptcha') this.message='security code invalid.';
                this.password='';
                this.recaptcha_code='';
               this.get_recaptcha_image();
              }).catch(function(error){
                //console.log(error);
                }).finally(() => this.show_overlay=false);  
        },//auth_login(evt)

        get_recaptcha_image(new_recaptcha='false'){
            this.show_overlay=true;
            var formData=new FormData();
            formData.append('new_recaptcha',new_recaptcha);
            axios.post('/get_recaptcha_image',formData).then(respose=>{   
                //console.log(respose.data) 
                 this.recaptcha_code='';
                if(respose.data=='none'){
                    this.recaptcha_image_contents='';
                    return;
                }             
                this.recaptcha_image_contents=respose.data;
                }).catch(function(error){
               // console.log(error);
                }).finally(() => this.show_overlay=false);             
        }//get_recaptcha_image
    },//methods
    mounted(){
        this.get_recaptcha_image();
    }
  }
</script>