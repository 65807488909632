<template>
<b-overlay :show="show_full_overlay" variant="trasprant" opacity="0.60" blur="2px" rounded="sm">
	<div class="container-fluid">
		<b-row>
			<b-col md="2"> </b-col>
			<b-col sm="6" md="4" v-show="today_calendar_image_contents">
				<b-card
				  title="تقویم امروز"
				  :img-src="'data:image/png;base64,'+today_calendar_image_contents"
				  img-alt="Today Calendar"
				  img-top
				  tag="article"
				  style="max-width: 20rem; direction:rtl; text-align:right;"
				  class="mb-2"
				  border-variant="light"
				>
		    	<b-card-text>
					<span v-if="today_shamsi_occasion!=''">
						{{today_shamsi_occasion}}<br>
					</span>
					<span v-if="today_gamari_occasion!=''">{{today_gamari_occasion}}<br></span>
					<span v-if="today_miladi_occasion!=''">{{today_miladi_occasion}}</span>
			    </b-card-text>
		      </b-card>
	     	</b-col>
			<b-col sm="6"  md="4" v-show="tomarrow_calendar_image_contents">
				<b-card
				  title="تقویم فردا"
				  :img-src="'data:image/png;base64,'+tomarrow_calendar_image_contents"
				  img-alt="Today Calendar"
				  img-top
				  tag="article"
				  style="max-width: 20rem; direction:rtl; text-align:right;"
				  class="mb-2"
				  border-variant="light"
				>
		    	<b-card-text>
					<span v-if="tomarrow_shamsi_occasion!=''">{{tomarrow_shamsi_occasion}}<br></span>
					<span v-if="tomarrow_gamari_occasion!=''">{{tomarrow_gamari_occasion}}<br></span>
					<span v-if="tomarrow_miladi_occasion!=''">{{tomarrow_miladi_occasion}}</span>
			    </b-card-text>
		    </b-card>
		  </b-col>
	</b-row>
  
</div>
</b-overlay>
							
</template>
<script>
	export default {
		data(){
			return{
				show_full_overlay:false,
				today_shamsi_occasion:'',
				today_miladi_occasion:'',
				today_gamari_occasion:'',
				tomarrow_shamsi_occasion:'',
				tomarrow_miladi_occasion:'',
				tomarrow_gamari_occasion:'',
				today_calendar_image_contents:null,
				tomarrow_calendar_image_contents:null,
			}
							
		},//data()
		mounted(){
			var formData=new FormData();
			formData.append('day','0');
			this.show_full_overlay=true; 
			axios.post('/get_calendar_image_day',formData)
             .then(respose=>{    
				 if(respose.data=='error') return; //error read data              
				 this.today_calendar_image_contents=respose.data.image_contents;
			     this.today_shamsi_occasion=respose.data.shamsi_occasion;
                 this.today_miladi_occasion=respose.data.miladi_occasion;
				 this.today_gamari_occasion=respose.data.gamari_occasion;                    
                 }).catch(function(error){
                          //console.log(error);
				 });
			var formData=new FormData();
			formData.append('day','1');
			this.show_full_overlay=true; 
			axios.post('/get_calendar_image_day',formData)
             .then(respose=>{    
				 if(respose.data=='error') return; //error read data              
				 this.tomarrow_calendar_image_contents=respose.data.image_contents;
			     this.tomarrow_shamsi_occasion=respose.data.shamsi_occasion;
                 this.tomarrow_miladi_occasion=respose.data.miladi_occasion;
				 this.tomarrow_gamari_occasion=respose.data.gamari_occasion;                    
                 }).catch(function(error){
                          //console.log(error);
                 }).finally(() => this.show_full_overlay=false);
      },//mounted()
	}
</script>